import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface,SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
@Component({
  selector: 'app-swipper',
  templateUrl: './swipper.component.html',
  styleUrls: ['./swipper.component.scss']
})
export class SwipperComponent implements OnInit {


  public slides = [
    { url: '/assets/images/gauges/a-cutting-metal4.png', clickAction: () => alert('custom click function') },
    { url: '/assets/images/gauges/a-cutting-metal.png', caption: 'Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/gauges/a-milling-machine2.jpg', caption: 'Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/gauges/a-calibration8.jpg', backgroundSize: 'contain', backgroundPosition: 'center' },
    { url: '/assets/images/gauges/a-surface-grinding-machine2.jpg', backgroundSize: 'contain', backgroundPosition: 'center' }
    //{ url: '/assets/images/gauges/hydrolic-press.png', backgroundSize: 'contain', backgroundPosition: 'center' },
    //{ url: '/assets/images/gauges/round-blade-grinding-machine.png', backgroundSize: 'contain', backgroundPosition: 'center' },
    //{ url: '/assets/images/gauges/round-grinding-machine2.png', backgroundSize: 'contain', backgroundPosition: 'center' },
    //{ url: '/assets/images/gauges/milling-machine3.jpg', caption: 'Gauge', href: 'https://amengineers.in/' },
  ];

  public config: SwiperConfigInterface = {
    //a11y: true,
    direction: 'horizontal',
    observer: false,
    centeredSlides: false,
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true,
    autoplay:true
  };
  

  constructor() { }


  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onSwiperEvent(event: string): void {
    console.log('Swiper event: ', event);
  }

  ngOnInit() {
  }

}


