<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-menu></app-menu>
      </div>
   </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        <div id="column-left" class="col-sm-3 hidden-xs">
         <app-left-panel></app-left-panel>
        </div>
    
    
    
    
    
         <div id="content" class="col-sm-9"><!--Moni-->
          <ul class="breadcrumb" style="display:block;">
              <li><a routerLink="/"><i class="fa fa-home"></i>&nbsp;Home</a></li>
              </ul>
              <div class="panel">
                <div class="panel-body">
                    
                    <div id="main-swipper">
                        <app-swipper></app-swipper>
                    </div>
  
  
  
  
  
  
  
  
          <!--Banner section start
          <div id="banner" class="box" style="display: inline-block">
            <div class="item">
              <a href="#"><img src="/assets/images/process/robots.jpg" alt="sub-banner1" class="img-responsive"></a>
            </div>
            <div class="item">
              <a href="#"><img src="/assets/images/process/process2.jpg" alt="sub-banner2" class="img-responsive"></a>
            </div>
          </div>
          Banner section ends-->
  
  
         <br/><br/>
          <div id="our-offerings">
              <app-our-offerings></app-our-offerings>
          </div>
          
          <br/><br/>
          <div id="our-testimonials"> 
              <app-our-testimonials></app-our-testimonials>
          </div>
          <div id="our-clients">
                <app-our-clients></app-our-clients>
          </div>
         <div id="our-contacts">
            <app-our-contact></app-our-contact>
         </div>
          
         
  
  
         
  
  
  
          
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
    
  </div>
  </div>
  </div><!--Moni-->
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  