<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/downloads.html">Downloads</a></li>
              </ul>
              <!--<h1>Downloads</h1>-->
              <div class="panel">
                <div class="panel-body">
                    <div class="row">
                        <div id="content" class="col-sm-9">
                          <h1>Your Account Has Been Created!</h1>
                          <p>Congratulations! Your new account has been successfully created!</p> <p>You can now take advantage of member privileges to enhance your online shopping experience with us.</p> <p>If you have ANY questions about the operation of this online shop, please e-mail the store owner.</p> <p>A confirmation has been sent to the provided e-mail address. If you have not received it within the hour, please <a href="Megnor Computer Pvt Ltd">contact us</a>.</p>      <div class="buttons">
                            <div class="pull-right"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/account" class="btn btn-primary">Continue</a></div>
                          </div>
                      </div>
                  </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  