
  <div class="what_we_area row" >
      <div class="tittle wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;background: rgba( 243, 156, 18, 0.5);">
              <h2 class="wow fadeInDown animated animated" style="animation-name: fadeInDown;">WHAT WE OFFER</h2>
              <h4 style=" margin:5px;padding-bottom:10px;">Following are the types of business we offer</h4>
      </div>
      <div class="row construction_iner">
          <div class="col-md-4 col-sm-6 construction wow fadeIn" data-wow-delay="0.2s"  style="visibility: visible; animation-name: fadeIn;">
            <div class="cns-img">
                  <img src="assets/images/we-offer/cns-11.jpg" alt="">
             </div>
             <div class="cns-content">
                  
                  <i class="fa fa-tachometer" aria-hidden="true"></i>
                  <a href="/#/products/gauges.html">Standard Gauges</a>
                  <p>Our experienced professionals designing, manufacturing and supplying auto gauges using the quality assured components and innovative technology.</p>
             </div>
          </div>
          <div class="col-md-4 col-sm-6 construction wow fadeIn" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeIn;">
            <div class="cns-img">
                  <img src="assets/images/we-offer/cns-21.jpg" alt="">
            </div>
            <div class="cns-content">
                <i class="fa fa-cogs" aria-hidden="true"></i>
                  <a href="/#/products/jigs-and-fixtures.html">Jigs & Fixtures</a>
                  <p>Designing and manufaturing exclusive range of Jig and Fixtures using optimum quality raw-material and sophisticated techniques for our clients.</p>
             </div>
          </div>
          <div class="col-md-4 col-sm-6 construction wow fadeIn" data-wow-delay="0.4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeIn;">
            <div class="cns-img">
                  <img src="assets/images/we-offer/cns-31.jpg" alt="">
            </div>
            <div class="cns-content">
                <i class="fa fa-wrench" aria-hidden="true"></i>
                  <a href="/#/products/accessories.html">Accessories</a>
                  <p>We manufacture all kinds of accurate and finely finished tools and accessories for special purpose machines and fabricated parts to suit our customer’s requirements.</p>
             </div>
          </div>
      </div>
  </div>