import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../common/services/auth.service';

@Component({
  selector: 'app-account-left-panel',
  templateUrl: './account-left-panel.component.html',
  styleUrls: ['./account-left-panel.component.scss']
})
export class AccountLeftPanelComponent implements OnInit {

  constructor(public auth: AuthService) { 
  }

  ngOnInit() {
  }

}
