
<div class="our-clients-area row" >
        <div class="tittle wow fadeInUp"  style="visibility: visible; animation-name: fadeInUp;">
            <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Our Clients</h2>
            <h4>Some of our most valueable clients</h4>
        </div>
        <div class="our-clients-carosel" style="width:100% !important;">
            <swiper  [config]="config" style="overflow: hidden;">
                <div *ngFor="let slide of slides" style="background-color: white;opacity:1;" >
                  <div style="background-color: white;opacity:1;">
                    <img src="{{slide}}" class="img-responsive" style="background-color: white;opacity:1;">
                  </div>
                </div>
            </swiper>
        </div>
    </div>