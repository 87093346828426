<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/checkout-success.html">Checkout Success</a></li>
              </ul>
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">





                        <div id="content" class="col-sm-9">
                            <h1>Your order has been placed!</h1>
                          <p>Your order has been successfully processed!</p><p>You can view your order history by going to the <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/account">my account</a> page and by clicking on <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/order">history</a>.</p><p>If your purchase has an associated download, you can go to the account <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/download">downloads</a> page to view them.</p><p>Please direct any questions you have to the <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=information/contact">store owner</a>.</p><p>Thanks for shopping with us online!</p>      <div class="buttons">
                            <div class="pull-right"><a routerLink="/" class="btn btn-primary">Continue</a></div>
                          </div>
                          </div>










                  </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  