<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/visionAndMission.html">Vision And Mission</a></li>
              </ul>
              <!--<h1>Vision And Mission</h1>-->
              <div  >
                    <img src="/assets/images/banners/mission-vision-banner.jpg" class="img-responsive">
              </div>
              <div class="panel">
                <div class="panel-body">
                    <div class="row">
                     <div class="contact_info" style="font-size: 14px;" align="justify">
                            <b>Our Mission :</b><br/>
                            To be globally recognize as professional, innovative and quality conscious enterprise 
                            by exceeding our customers’ expectations in quality, delivery, and cost through continuous 
                            improvement in product, processes, people and technology.

                            <br/><br/>
                            <b>Our Vision :</b><br/>
                            To be a world’s leading company  providing cost effective, innovative and superior Gauge and 
                            Fixture engineering solutions to meet or even surpass our customers’ expectations consistently.

                            
                            <br/><br/>
                            <b>Core Values :</b><br/>
                            Integrity: Honesty & Harmony in what we say, do and think<br/>
                            Innovation: Forward thinking, planning & execution to expand our horizons<br/>
                            Passion: Enthusiasm, desire and commitment to succeed<br/>
                            Quality: Excellence in service & products we are proud to stand behind<br/>
                            Team Work: We all succeed together <br/>
      
      
                      </div>
                  </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  