<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-account-left-panel></app-account-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/account/password.html">Password</a></li>
              </ul>
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                    


















                            <div id="content" class="col-sm-9">
                                 <h1>Change Password</h1>
                                  <form action="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/password" method="post" enctype="multipart/form-data" class="form-horizontal">
                                    <fieldset>
                                      <legend>Your Password</legend>
                                      <div class="form-group required">
                                        <label class="col-sm-2 control-label" for="input-password">Password</label>
                                        <div class="col-sm-10">
                                          <input type="password" name="password" value="" placeholder="Password" id="input-password" class="form-control">
                                                      </div>
                                      </div>
                                      <div class="form-group required">
                                        <label class="col-sm-2 control-label" for="input-confirm">Password Confirm</label>
                                        <div class="col-sm-10">
                                          <input type="password" name="confirm" value="" placeholder="Password Confirm" id="input-confirm" class="form-control">
                                                      </div>
                                      </div>
                                    </fieldset>
                                    <div class="buttons clearfix">
                                      <div class="pull-left"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/account" class="btn btn-default">Back</a></div>
                                      <div class="pull-right">
                                        <input type="submit" value="Continue" class="btn btn-primary">
                                      </div>
                                    </div>
                                  </form>
                                  </div>


























































































                    </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  