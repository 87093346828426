<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-menu></app-menu>
      </div>
   </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
     
        
  
          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/products.html">Products</a></li>
              </ul>
              <div class="panel">
                      
      
  




  
                    <div  class="col-sm-12">
                        <h2>Refine Search</h2>
                        <div class="row">
                          <div class="col-sm-12 category_list">
                            <ul>
                                <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20_59">Mac (0)</a></li>
                                <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20_61">PC (0)</a></li>
                                <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20_26">Tablets (0)</a></li>
                                <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20_27">Laptop (1)</a></li>
                              </ul>
                          </div>
                        </div>
                        <div class="row category_filter">
                            <div class="col-md-6 btn-list-grid">
                                <div class="btn-group">
                                  <button type="button" id="list-view" class="btn" data-toggle="tooltip" title="" data-original-title="List"></button>
                                  <button type="button" id="grid-view" class="btn" data-toggle="tooltip" title="" data-original-title="Grid"></button>
                                </div>
                                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/compare" id="compare-total">Product Compare (0)</a>
                              </div>
                              <div class="col-md-6 product_filter" style="float: right;"> 
                                <div class="col-sm-3 col-md-3 text-right sort_label">
                                  <label class="control-label" for="input-sort">Sort By:</label>
                                </div>
                                <div class="col-sm-3 col-md-3 text-right sort" style="width:142px;">
                                  <select id="input-sort" class="form-control hasCustomSelect" style="width:142px; position: absolute; height: 30px; font-size: 12px;">
                                    <option value="" selected="selected">Default</option>
                                    <option value="">Name (A - Z)</option>
                                    <option value="">Name (Z - A)</option>
                                    <option value="">Price (Low &gt; High)</option>
                                    <option value="">Price (High &gt; Low)</option>
                                    <option value="">Rating (Highest)</option>
                                    <option value="">Rating (Lowest)</option>
                                    <option value="">Model (A - Z)</option>
                                    <option value="">Model (Z - A)</option>
                                  </select>
                                  <span class="customSelect form-control" style="display: inline-block;"><span class="customSelectInner" style="width: 132px; display: inline-block;">Default</span></span>
                                </div>
                                <div class="col-md-3 text-right limit_label">
                                  <label class="control-label" for="input-limit">Show:</label>
                                </div>
                                <div class="col-xs-3 col-sm-3 col-md-3 text-right limit">
                                  <select id="input-limit" class="form-control hasCustomSelect" style="width: 62px; position: absolute;height: 30px; font-size: 12px;">
                                      <option value="" selected="selected">8</option>
                                      <option value="">25</option>
                                      <option value="">50</option>
                                      <option value="">75</option>
                                      <option value="">100</option>
                                  </select>
                                  <span class="customSelect form-control" style="display: inline-block;"><span class="customSelectInner" style="width: 52px; display: inline-block;">8</span></span>
                                </div>
                              </div>
                        </div>



































           
            <div class="row">
                      <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6">
                <div class="product-thumb product-block">
                  <div class="image"><a routerLink="/product-information.html"><img src="http://opencart.templatemela.com/OPC05/OPC050106/image/cache/catalog/product/15-500x500-150x150.jpg" alt="Palm Treo Pro" title="Palm Treo Pro" class="img-responsive"></a></div>
                
                    <div class="caption">
                   
                  <h4>
                                      <a routerLink="/product-information.html">Palm Treo Pro</a>
                                
                   </h4>
                      <div class="description">
                   
          Redefine your workday with the Palm Treo Pro smartphone. Perfectly balanced, you can respond to b..</div>
                                                                  <div class="wishlist">
                      <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('29');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button></div>
                       <div class="compare">
                      <button type="button" data-toggle="tooltip" title="" onclick="compare.add('29');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button></div>
                   
      
                                      <p class="price">
                                          $279.99                                                      <span class="price-tax">Ex Tax: $279.99</span>
                                        </p>
                                  
                    
                     <div class="cart">
                                    <button type="button" onclick="cart.add('29');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                 </div>
                </div>
                </div>
               </div>

               
                      <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6">
                <div class="product-thumb product-block">
                  <div class="image"><a routerLink="/product-information.html"><img src="http://opencart.templatemela.com/OPC05/OPC050106/image/cache/catalog/product/19-500x500-150x150.jpg" alt="Suspendisse massa" title="Suspendisse massa" class="img-responsive"></a></div>
                
                    <div class="caption">
                   
                  <h4>
                                      <a routerLink="/product-information.html" title="Suspendisse massa">Suspendisse mas...</a>
                                
                   </h4>
                      <div class="description">
                   
          Product 8
      ..</div>
                                                                  <div class="wishlist">
                      <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('35');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button></div>
                       <div class="compare">
                      <button type="button" data-toggle="tooltip" title="" onclick="compare.add('35');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button></div>
                   
      
                                      <p class="price">
                                          $100.00                                                      <span class="price-tax">Ex Tax: $100.00</span>
                                        </p>
                                  
                    
                     <div class="cart">
                                    <button type="button" onclick="cart.add('35');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                 </div>
                </div>
                </div>
               </div>
                      <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6">
                <div class="product-thumb product-block">
                  <div class="image"><a routerLink="/product-information.html"><img src="http://opencart.templatemela.com/OPC05/OPC050106/image/cache/catalog/product/20-500x500-150x150.jpg" alt="Suspendisse potenti" title="Suspendisse potenti" class="img-responsive"></a></div>
                
                    <div class="caption">
                   
                  <h4>
                                      <a routerLink="/product-information.html" title="Suspendisse potenti">Suspendisse pot...</a>
                                
                   </h4>
                      <div class="description">
                   
          Imagine the advantages of going big without slowing down. The big 19" 941BW monitor combines wide..</div>
                                                                  <div class="wishlist">
                      <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('33');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button></div>
                       <div class="compare">
                      <button type="button" data-toggle="tooltip" title="" onclick="compare.add('33');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button></div>
                   
      
                                      <p class="price">
                                          $200.00                                                      <span class="price-tax">Ex Tax: $200.00</span>
                                        </p>
                                  
                    
                     <div class="cart">
                                    <button type="button" onclick="cart.add('33');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                 </div>
                </div>
                </div>
               </div>
                      <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6">
                <div class="product-thumb product-block">
                  <div class="image"><a routerLink="/product-information.html"><img src="http://opencart.templatemela.com/OPC05/OPC050106/image/cache/catalog/product/22-500x500-150x150.jpg" alt="varius neque at enim " title="varius neque at enim " class="img-responsive"></a></div>
                
                    <div class="caption">
                   
                  <h4>
                                      <a routerLink="/product-information.html" title="varius neque at enim ">varius neque at...</a>
                                
                   </h4>
                      <div class="description">
                   
          Unprecedented power. The next generation of processing technology has arrived. Built into the new..</div>
                                                                  <div class="wishlist">
                      <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('46');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button></div>
                       <div class="compare">
                      <button type="button" data-toggle="tooltip" title="" onclick="compare.add('46');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button></div>
                   
      
                                      <p class="price">
                                          $1,000.00                                                      <span class="price-tax">Ex Tax: $1,000.00</span>
                                        </p>
                                  
                    
                     <div class="cart">
                                    <button type="button" onclick="cart.add('46');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                 </div>
                </div>
                </div>
               </div><div class="clearfix" style="display: none !important;"></div>







               
                    </div>
             
            <div class="row">
             <div class="pagination_link" style="width:100%">
              <div class="text-right pull-right"><ul class="pagination"><li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20&amp;page=1">|&lt;</a></li><li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20&amp;page=1">&lt;</a></li><li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20&amp;page=1">1</a></li><li class="active"><span>2</span></li></ul></div>
              <div class="text-left pull-left results">Showing 9 to 12 of 12 (2 Pages)</div>
            </div>
            </div>
                        </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
                  </div>
                </div>
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  

  

