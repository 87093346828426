import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-gauges',
  templateUrl: './gauges.component.html',
  styleUrls: ['./gauges.component.scss']
})
export class GaugesComponent implements OnInit {
  imageURL =''
  imageCaption = ''
  public products = [
    { url: '/assets/images/products/gauges/snap-gauges/snap-gauge1.png', caption: 'Snap Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/snap-gauges/snap-gauge2.jpg', caption: 'Snap Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/snap-gauges/snap-gauge31.jpg', caption: 'Snap Gauge', href: 'https://amengineers.in/' },
    //{ url: '/assets/images/products/gauges/snap-gauges/snap-gauge4.jpg', caption: 'Snap Gauge', href: 'https://amengineers.in/' },

    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge1.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge2.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge3.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge4.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge5.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },

    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge1.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge2.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge3.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge4.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge5.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge6.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },

    { url: '/assets/images/products/gauges/taper-plug-gauges/taper-plug-gauge1.png', caption: 'Taper Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/taper-plug-gauges/taper-plug-gauge2.png', caption: 'Taper Plug Gauge', href: 'https://amengineers.in/' },
  


    { url: '/assets/images/products/gauges/industrial-gauges/industrial-gauge1.png', caption: 'Industrail Gauge', href: 'https://amengineers.in/' }
  ];


  public snapGauges = [
    { url: '/assets/images/products/gauges/snap-gauges/snap-gauge1.png', caption: 'Snap Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/snap-gauges/snap-gauge2.jpg', caption: 'Snap Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/snap-gauges/snap-gauge31.jpg', caption: 'Snap Gauge', href: 'https://amengineers.in/' }
  ];
  public ringGauges = [
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge1.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge2.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge3.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge4.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/ring-gauges/ring-gauge5.png', caption: 'Ring Gauge', href: 'https://amengineers.in/' }
  ];

  public plugGauges = [
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge1.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge2.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge3.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge4.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge5.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/plug-gauges/plug-gauge6.png', caption: 'Plug Gauge', href: 'https://amengineers.in/' }
  ];

  public taperPlugGauges = [
    { url: '/assets/images/products/gauges/taper-plug-gauges/taper-plug-gauge1.png', caption: 'Taper Plug Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/taper-plug-gauges/taper-plug-gauge2.png', caption: 'Taper Plug Gauge', href: 'https://amengineers.in/' }
  ];


  public positionGauges = [
    { url: '/assets/images/products/gauges/position-gauges/position-gauge1.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge2.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge3.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge4.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge5.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge6.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge7.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge8.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge9.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge10.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge11.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge12.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge13.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge14.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge15.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge16.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge17.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge18.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge19.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge20.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge21.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge22.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge23.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge24.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge25.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge26.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/position-gauges/position-gauge27.jpg', caption: 'Position Gauge', href: 'https://amengineers.in/' }
  ];



  public angleCheckingGauges = [
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge1.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge2.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge3.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge4.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge5.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge6.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge7.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge8.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge9.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge10.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge11.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge12.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge13.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge14.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge15.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge16.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge17.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge18.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/angle-checking-gauges/angle-checking-gauge19.jpg', caption: 'Angle Checking Gauge', href: 'https://amengineers.in/' }
  ];

  public clampHolePositionGauges = [
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge1.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge2.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge3.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge4.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge5.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge6.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge7.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge8.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge9.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge10.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge11.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge12.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge13.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge14.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge15.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge16.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge17.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge18.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge19.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge20.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge21.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/clamp-hole-position-gauges/clamp-hole-position-gauge22.jpg', caption: 'Clamp Hole Position Gauge', href: 'https://amengineers.in/' }
  ];
  
  public crankAssyHeightGauges = [
    { url: '/assets/images/products/gauges/crank-assy-height-gauges/crank-assy-height-gauge1.jpg', caption: 'Crank Assy Height Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/crank-assy-height-gauges/crank-assy-height-gauge2.jpg', caption: 'Crank Assy Height Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/crank-assy-height-gauges/crank-assy-height-gauge3.jpg', caption: 'Crank Assy Height Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/crank-assy-height-gauges/crank-assy-height-gauge4.jpg', caption: 'Crank Assy Height Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/crank-assy-height-gauges/crank-assy-height-gauge5.jpg', caption: 'Crank Assy Height Gauge', href: 'https://amengineers.in/' }
  ];

  public crankAssyHolePitchGauges = [
    { url: '/assets/images/products/gauges/crank-assy-hole-pitch-gauges/crank-assy-hole-pitch-gauge1.jpg', caption: 'Crank Assy Hole Pitch Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/crank-assy-hole-pitch-gauges/crank-assy-hole-pitch-gauge2.jpg', caption: 'Crank Assy Hole Pitch Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/crank-assy-hole-pitch-gauges/crank-assy-hole-pitch-gauge3.jpg', caption: 'Crank Assy Hole Pitch Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/crank-assy-hole-pitch-gauges/crank-assy-hole-pitch-gauge4.jpg', caption: 'Crank Assy Hole Pitch Gauge', href: 'https://amengineers.in/' }
  ];


  public distanceGauges = [
    { url: '/assets/images/products/gauges/distance-gauges/distance-gauge1.jpg', caption: 'Distance Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/distance-gauges/distance-gauge2.jpg', caption: 'Distance Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/distance-gauges/distance-gauge3.jpg', caption: 'Distance Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/distance-gauges/distance-gauge4.jpg', caption: 'Distance Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/distance-gauges/distance-gauge5.jpg', caption: 'Distance Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/distance-gauges/distance-gauge6.jpg', caption: 'Distance Gauge', href: 'https://amengineers.in/' }
  ];


  public holePositionDistanceCheckingGauges = [
    { url: '/assets/images/products/gauges/hole-position-distance-checking-gauges/hole-position-distance-checking-gauge1.jpg', caption: 'Hole Position Distance Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/hole-position-distance-checking-gauges/hole-position-distance-checking-gauge2.jpg', caption: 'Hole Position Distance Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/hole-position-distance-checking-gauges/hole-position-distance-checking-gauge3.jpg', caption: 'Hole Position Distance Checking Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/hole-position-distance-checking-gauges/hole-position-distance-checking-gauge4.jpg', caption: 'Hole Position Distance Checking Gauge', href: 'https://amengineers.in/' }
  ];

  public spacerHolePitchProfileGauges = [
    { url: '/assets/images/products/gauges/spacer-hole-pitch-profile-gauges/spacer-hole-pitch-profile-gauge1.jpg', caption: 'Spacer Hole Pitch Profile Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/spacer-hole-pitch-profile-gauges/spacer-hole-pitch-profile-gauge2.jpg', caption: 'Spacer Hole Pitch Profile Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/spacer-hole-pitch-profile-gauges/spacer-hole-pitch-profile-gauge3.jpg', caption: 'Spacer Hole Pitch Profile Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/spacer-hole-pitch-profile-gauges/spacer-hole-pitch-profile-gauge4.jpg', caption: 'Spacer Hole Pitch Profile Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/spacer-hole-pitch-profile-gauges/spacer-hole-pitch-profile-gauge5.jpg', caption: 'Spacer Hole Pitch Profile Gauge', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/gauges/spacer-hole-pitch-profile-gauges/spacer-hole-pitch-profile-gauge6.jpg', caption: 'Spacer Hole Pitch Profile Gauge', href: 'https://amengineers.in/' }
  ];
  constructor(public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
  }

  setImageURL(url){
    this.imageURL = url;
    this.ngxSmartModalService.getModal('myBootstrapModal').open();
  }
  ngAfterViewInit() {
    const obj: Object = {
      prop1: 'test',
      prop2: true,
      prop3: [{a: 'a', b: 'b'}, {c: 'c', d: 'd'}],
      prop4: 327652175423
    };

    this.ngxSmartModalService.setModalData(obj, 'myModal');
  }

}
