import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/auth-guard/auth.guard';
import {HomeComponent} from './pages/home/home.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {HistoryComponent} from './pages/history/history.component';
import {VisionAndMissionComponent} from './pages/vision-and-mission/vision-and-mission.component';
import {WallOfGratitudeComponent} from './pages/wall-of-gratitude/wall-of-gratitude.component';
import {QualityPolicyComponent} from './pages/quality-policy/quality-policy.component';
import {ProductsComponent} from './pages/product/products/products.component';
import { ProductInformationComponent } from './pages/product/product-information/product-information.component';
import {GaugesComponent} from './pages/product/product-list/gauges/gauges.component';
import {JigsAndFixturesComponent} from './pages/product/product-list/jigs-and-fixtures/jigs-and-fixtures.component';
import {AccessoriesComponent} from './pages/product/product-list/accessories/accessories.component';
import {MachineriesComponent} from './pages/machineries/machineries.component';
import {DownloadsComponent} from './pages/downloads/downloads.component';
import {VideosComponent} from './pages/videos/videos.component';
import {ContactUsComponent} from './pages/contact-us/contact-us.component';
import {CareersComponent} from './pages/careers/careers.component';
import {ClientsComponent} from './pages/clients/clients.component';
import {LoginComponent} from './pages/account/login/login.component';
import {RegisterComponent} from './pages/account/register/register.component';
import {RegisterSuccessComponent} from './pages/account/register-success/register-success.component';
import { ForgottenPasswordComponent } from './pages/account/forgotten-password/forgotten-password.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CheckoutSuccessComponent } from './pages/checkout-success/checkout-success.component';

import { MyAccountComponent } from './pages/account/my-account/my-account.component';
import { EditAccountComponent } from './pages/account/edit-account/edit-account.component';
import { AddressBookComponent } from './pages/account/address-book/address-book.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { OrderHistoryComponent } from './pages/account/order-history/order-history.component';
import { OrderInformationComponent } from './pages/account/order-information/order-information.component';
import { WishListComponent } from './pages/account/wish-list/wish-list.component';
import { RewardPointsComponent } from './pages/account/reward-points/reward-points.component';
import { ReturnsComponent } from './pages/account/returns/returns.component';
import { TransactionsComponent } from './pages/account/transactions/transactions.component';
import { NewsletterComponent } from './pages/account/newsletter/newsletter.component';
import { RecurringPaymentsComponent } from './pages/account/recurring-payments/recurring-payments.component';
import { EditAddressComponent } from './pages/account/edit-address/edit-address.component';


import {SitemapComponent} from './pages/sitemap/sitemap.component';

const routes: Routes =[
  {path:'', component: HomeComponent},
  {path:'aboutUs.html', component: AboutUsComponent},
  {path:'history.html', component: HistoryComponent},
  {path:'visionAndMission.html', component: VisionAndMissionComponent},
  {path:'wallOfGratitude.html', component: WallOfGratitudeComponent},
  {path:'qualityPolicy.html', component: QualityPolicyComponent},
  {path:'products.html', component: ProductsComponent},
  {path:'product-information.html', component: ProductInformationComponent},
  {path:'products/gauges.html', component: GaugesComponent},
  {path:'products/jigs-and-fixtures.html', component: JigsAndFixturesComponent},
  {path:'products/accessories.html', component: AccessoriesComponent},
  {path:'machineries.html', component: MachineriesComponent},
  {path:'downloads.html', component: DownloadsComponent},
  {path:'videos.html', component: VideosComponent},
  {path:'contactUs.html', component: ContactUsComponent},
  {path:'clients.html', component: ClientsComponent},
  {path:'careers.html', component: CareersComponent},
  {path:'login.html', component: LoginComponent},
  {path:'register.html', component: RegisterComponent},
  {path:'register-success.html', component: RegisterSuccessComponent},
  {path:'shopping-cart.html', component: ShoppingCartComponent},
  {path:'checkout.html', component: CheckoutComponent},
  {path:'checkout-success.html', component: CheckoutSuccessComponent},

  {path:'my-account.html', component: MyAccountComponent, canActivate: [AuthGuard]},
  {path:'edit-account.html', component: EditAccountComponent, canActivate: [AuthGuard]},
  {path:'address-book.html', component: AddressBookComponent, canActivate: [AuthGuard]},
  {path:'change-password.html', component: ChangePasswordComponent, canActivate: [AuthGuard]},
  {path:'order-history.html', component: OrderHistoryComponent, canActivate: [AuthGuard]},
  {path:'order-information.html', component: OrderInformationComponent, canActivate: [AuthGuard]},
  {path:'wish-list.html', component: WishListComponent, canActivate: [AuthGuard]},
  {path:'reward-points.html', component: RewardPointsComponent, canActivate: [AuthGuard]},
  {path:'returns.html', component: ReturnsComponent, canActivate: [AuthGuard]},
  {path:'transactions.html', component: TransactionsComponent, canActivate: [AuthGuard]},
  {path:'newsletter.html', component: NewsletterComponent, canActivate: [AuthGuard]},
  {path:'recurring-payments.html', component: RecurringPaymentsComponent, canActivate: [AuthGuard]},
  {path:'edit-address.html', component: EditAddressComponent, canActivate: [AuthGuard]},
  
  {path:'sitemap.html', component: SitemapComponent}

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
