import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-left-panel',
  templateUrl: './common-left-panel.component.html',
  styleUrls: ['./common-left-panel.component.scss']
})
export class CommonLeftPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
