import { Component, OnInit } from '@angular/core';
import { UserService } from '../../common/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  constructor(private userService: UserService) { 
    this.userService.getAPIUsers();

  }

  ngOnInit() {
    // adding an image url dynamically.
    setTimeout(() => {
      console.log('adding an image url dynamically.');
    }, 2000);
  }

}
