import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wall-of-gratitude',
  templateUrl: './wall-of-gratitude.component.html',
  styleUrls: ['./wall-of-gratitude.component.scss']
})
export class WallOfGratitudeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
