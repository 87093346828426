<div class="box category">
    <div class="box-heading">Categories</div>
    <div class="box-content ">
      <ul class="box-category treeview-list treeview">
          <li class="expandable"><div class="hitarea expandable-hitarea"></div>
            <a routerLink="/products/gauges.html">Gauges (13)</a>
          </li>
          <li class="expandable"><div class="hitarea expandable-hitarea"></div>
            <a routerLink="/products/jigs-and-fixtures.html">Jigs And Fixtures (5)</a>
          </li>
          <li class="expandable"><div class="hitarea expandable-hitarea"></div>
            <a routerLink="/products/accessories.html" >Accessories (2)</a>
          </li>
          <li>
            <a  routerLink="/machineries.html">Machineries (1)</a>
          </li>
        </ul>	
    </div>
  </div>
  <div class="box">
    <div class="box-heading">Information</div>
      <div class="list-group information box-content">
        <a  routerLink="/aboutUs.html" class="list-group-item">About Us</a>
        <a  routerLink="/aboutUs.html" class="list-group-item">Delivery Information</a>
        <a  routerLink="/aboutUs.html" class="list-group-item">Privacy Policy</a>
        <a  routerLink="/aboutUs.html" class="list-group-item">Terms &amp; Conditions</a>
        <a  routerLink="/contactUs.html" class="list-group-item">Contact Us</a>
        <a  routerLink="/sitemap.html" class="list-group-item">Site Map</a>
        <!--
        <a class="list-group-item" href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=information/information&amp;information_id=4">About Us</a>
        <a class="list-group-item" href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=information/information&amp;information_id=6">Delivery Information</a>
        <a class="list-group-item" href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=information/information&amp;information_id=3">Privacy Policy</a>
        <a class="list-group-item" href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=information/information&amp;information_id=5">Terms &amp; Conditions</a>
        <a class="list-group-item" href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=information/contact">Contact Us</a>
        <a class="list-group-item" href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=information/sitemap">Site Map</a>-->
      </div>
  </div>   
  
  
  <!--
  <div class="box">
    <div class="box-heading">Specials</div>
    <div class="box-content">
      <div class="box-product productbox-grid" id="special-grid">
        <div class="product-items">
          <div class="product-block product-thumb transition">
            <div class="product-block-inner ">
              <div class="image">
                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/product&amp;product_id=42"><img src="/assets/images/1-500x500-80x80.jpg" alt=" Aliquam erat volutpat" title=" Aliquam erat volutpat" class="img-responsive"></a>
                  <span class="saleicon sale">Sale</span>
              </div>
              <div class="caption">
                <h4><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/product&amp;product_id=42" title=" Aliquam erat volutpat"> Comparators</a></h4>
                <p class="price">
                  <span class="price-new">Rs110.00</span> <span class="price-old">Rs122.00</span>
                  <span class="price-tax">Ex Tax: Rs90.00</span>
                </p>
                <div class="rating">
                  <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i></span>
                  <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i></span>
                  <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i></span>
                  <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i></span>
                  <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                </div>
              </div>
              <div class="button-group">
                <button type="button" onclick="cart.add('42');"><span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                <button class="wishlist_button" type="button" data-toggle="tooltip" title="" onclick="wishlist.add('42');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i></button>
                <button class="compare_button" type="button" data-toggle="tooltip" title="" onclick="compare.add('42');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="product-items">
          <div class="product-block product-thumb transition">
            <div class="product-block-inner ">
              <div class="image">
                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/product&amp;product_id=30"><img src="/assets/images/2-500x500-80x80.jpg" alt="Aliquam suscipit" title="Aliquam suscipit" class="img-responsive"></a>
                  <span class="saleicon sale">Sale</span>
              </div>
              <div class="caption">
                <h4><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/product&amp;product_id=30" title="Aliquam suscipit">Air Gauges</a></h4>
                <p class="price">
                  <span class="price-new">Rs98.00</span> <span class="price-old">Rs122.00</span>
                  <span class="price-tax">Ex Tax: Rs80.00</span>
                </p>		
              </div>
              <div class="button-group">
                <button type="button" onclick="cart.add('30');"><span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                <button class="wishlist_button" type="button" data-toggle="tooltip" title="" onclick="wishlist.add('30');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i></button>
                <button class="compare_button" type="button" data-toggle="tooltip" title="" onclick="compare.add('30');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i></button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  </div> -->



  <span class="special_default_width" style="display:none; visibility:hidden"></span>
  <div id="banner" class="box">
    <div class="item">
      <a href="#"><img src="/assets/images/process/process3.jpg" alt="Left-Banner" class="img-responsive"></a>
    </div>
  </div>
  <span class="bestseller_default_width" style="display:none; visibility:hidden"></span>