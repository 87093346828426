import { Injectable } from '@angular/core';
import { RequestOptions, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConstantsService } from '../services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private _httpClient: HttpClient,private _constant: ConstantsService) { 
    //this.getEnqueries();
  }

  getEnqueries() {
    console.log("Enqueries");
    let requestOptions = new RequestOptions({ headers:null, withCredentials: true });
    //return this._http.get(this._constant.baseAPIUrl).subscribe((data: Response) => {console.log(JSON.stringify(data.json()));data.json()});
    this._httpClient.get(this._constant.baseAPIUrl+"/enqueries").subscribe((res : any[])=>{
      console.log(JSON.stringify(res));
  });
  } 

}
