import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss']
})
export class OurClientsComponent implements OnInit {
    public config: SwiperConfigInterface = {
      a11y: true,
      direction: 'horizontal',
      observer: false,
      centeredSlides: false,
      slidesPerView: 4,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: false,
      pagination: false,
      autoplay:true,
      roundLengths:true
    };
  
    public slides = [
      '/assets/images/clients/delphi-tvs.jpg',
      '/assets/images/clients/ford.jpg',
      '/assets/images/clients/greentech.jpg',
      '/assets/images/clients/mm-forgings.jpg',
      '/assets/images/clients/motherson.png',
      '/assets/images/clients/tvs.png',
      '/assets/images/clients/venu-engineering-services.png',
      '/assets/images/clients/iljin.jpg'
    ];
  constructor() { }

  ngOnInit() {
  }

}
