<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-account-left-panel></app-account-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/account/address-book.html">Address Book</a></li>
              </ul>
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                    













                            <div id="content" class="col-sm-9">
                                         <h2>Address Book Entries</h2>
                                        <table class="table table-bordered table-hover">
                                            <tbody><tr>
                                      <td class="text-left">amengineers amengineers<br>Am Engineers<br>amengineers<br>chennai 6000048<br>Tamil Nadu<br>India</td>
                                      <td class="text-right"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/address/edit&amp;address_id=57" class="btn btn-info">Edit</a> &nbsp; <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/address/delete&amp;address_id=57" class="btn btn-danger">Delete</a></td>
                                    </tr>
                                          </tbody></table>
                                        <div class="buttons clearfix">
                                    <div class="pull-left"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/account" class="btn btn-default">Back</a></div>
                                    <div class="pull-right"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/address/add" class="btn btn-primary">New Address</a></div>
                                  </div>
                                  </div>































































































                    </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  