<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-account-left-panel></app-account-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/account/order-history.html">Order History</a></li>
              </ul>
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                    





















                            <div id="content" class="col-sm-9"> <h1>Order History</h1>
                                        <div class="table-responsive">
                                    <table class="table table-bordered table-hover">
                                      <thead>
                                        <tr>
                                          <td class="text-right">Order ID</td>
                                          <td class="text-left">Order Status</td>
                                          <td class="text-left">Date Added</td>
                                          <td class="text-right">No. of Products</td>
                                          <td class="text-left">Customer</td>
                                          <td class="text-right">Total</td>
                                          <td></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                                    <tr>
                                          <td class="text-right">#145</td>
                                          <td class="text-left">Pending</td>
                                          <td class="text-left">08/09/2019</td>
                                          <td class="text-right">1</td>
                                          <td class="text-left">amengineers amengineers</td>
                                          <td class="text-right">$105.00</td>
                                          <td class="text-right"><a routerLink="/order-information.html" data-toggle="tooltip" title="" class="btn btn-info" data-original-title="View"><i class="fa fa-eye"></i></a></td>
                                        </tr>
                                                    <tr>
                                          <td class="text-right">#144</td>
                                          <td class="text-left">Pending</td>
                                          <td class="text-left">08/09/2019</td>
                                          <td class="text-right">1</td>
                                          <td class="text-left">amengineers amengineers</td>
                                          <td class="text-right">$85.00</td>
                                          <td class="text-right"><a routerLink="/order-information.html" data-toggle="tooltip" title="" class="btn btn-info" data-original-title="View"><i class="fa fa-eye"></i></a></td>
                                        </tr>
                                                  </tbody>
                                    </table>
                                  </div>
                                  <div class="text-right"></div>
                                        <div class="buttons clearfix">
                                    <div class="pull-right"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/account" class="btn btn-primary">Continue</a></div>
                                  </div>
                                  </div>























































































                    </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  