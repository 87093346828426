<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-account-left-panel></app-account-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/account/wish-list.html">Wish List</a></li>
              </ul>
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                    





















                            <div id="content" class="col-sm-9 wishlist-info">
                                <h1>My Wish List</h1>
                                        <table class="table table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <td class="text-center">Image</td>
                                        <td class="text-left">Product Name</td>
                                        <td class="text-left">Model</td>
                                        <td class="text-right">Stock</td>
                                        <td class="text-right">Unit Price</td>
                                        <td class="text-right">Action</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                                <tr>
                                        <td class="text-center">              <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/product&amp;product_id=40"><img src="http://opencart.templatemela.com/OPC05/OPC050106/image/cache/catalog/product/6-500x500-47x47.jpg" alt="Donec semper sem nec" title="Donec semper sem nec"></a>
                                          </td>
                                        <td class="text-left"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/product&amp;product_id=40">Donec semper sem nec</a></td>
                                        <td class="text-left">product 11</td>
                                        <td class="text-right">In Stock</td>
                                        <td class="text-right">              <div class="price">
                                                            $101.00                              </div>
                                          </td>
                                        <td class="text-right"><button type="button" onclick="cart.add('40');" data-toggle="tooltip" title="" class="btn btn-primary" data-original-title="Add to Cart"><i class="fa fa-shopping-cart"></i></button>
                                          <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/wishlist&amp;remove=40" data-toggle="tooltip" title="" class="btn btn-danger" data-original-title="Remove"><i class="fa fa-times"></i></a></td>
                                      </tr>
                                                <tr>
                                        <td class="text-center">              <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/product&amp;product_id=49"><img src="http://opencart.templatemela.com/OPC05/OPC050106/image/cache/catalog/product/21-500x500-47x47.jpg" alt="Tellus eu volutpat varius" title="Tellus eu volutpat varius"></a>
                                          </td>
                                        <td class="text-left"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/product&amp;product_id=49">Tellus eu volutpat varius</a></td>
                                        <td class="text-left">SAM1</td>
                                        <td class="text-right">Pre-Order</td>
                                        <td class="text-right">              <div class="price">
                                                            $199.99                              </div>
                                          </td>
                                        <td class="text-right"><button type="button" onclick="cart.add('49');" data-toggle="tooltip" title="" class="btn btn-primary" data-original-title="Add to Cart"><i class="fa fa-shopping-cart"></i></button>
                                          <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/wishlist&amp;remove=49" data-toggle="tooltip" title="" class="btn btn-danger" data-original-title="Remove"><i class="fa fa-times"></i></a></td>
                                      </tr>
                                              </tbody>
                                  </table>
                                        <div class="buttons clearfix">
                                    <div class="pull-right"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/account" class="btn btn-primary">Continue</a></div>
                                  </div>
                                  </div>























































































                    </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  