<div class="container" style="background-color:#FFF;">
  <app-header></app-header>
  <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-menu></app-menu>
      </div>
   </div>
  <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
      



        <div id="column-left" class="col-sm-3 hidden-xs">
            <app-common-left-panel></app-common-left-panel>
        </div>
    
    
    
    
    
     
        <div id="content" class="col-sm-9"><ul class="breadcrumb">
            <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
            <li><a  routerLink="/gauges.html">Gauges</a></li>
            </ul>
            <!--<h1>Downloads</h1>-->
            <div>
                  <img src="/assets/images/banners/gauges-banner1.jpg" class="img-responsive">
            </div><br/><br/>
            <div class="panel">
              <div class="panel-body">
                  <div class="row">
                   <div class="contact_info">
    




                        <div class="our-testimonial-area row">
                                <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                    <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Snap Gauges</h2>
                                    <h4 style=" margin:5px;padding-bottom:10px;">
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Range:</b> 2.50 to 100.00 mm. Above 100 mm available on request. Available in 2 Types - Single & Double
                                            Ended. Also manufactured as per customer drawing / requirement.
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Specifications:</b> Single ended as per IS 8023: 1991 and Double Ended as per IS 4583: 1968
                                            Manufacturing tolerance as per IS: 3455-1971, IS: 919-1963.
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Material:</b> OHNS / EN31 / Carbide / HCHCR
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Hardness:</b> 55–58 HRC & 58-60HRC
                                    </h4>
                                </div>
                                <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                    <div *ngFor="let product of snapGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                        <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                            <div class="image" style="width:100%;">
                                                <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                            </div>
                                            <div class="caption">
                                                <h4 style="padding-top:10px;">
                                                    <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                </h4>
                                                <div class="description">
                                                    Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                </div>
                                                <div class="wishlist">
                                                    <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                </div>
                                                <div class="compare">
                                                    <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                </div>
                                                <!--<p class="price">
                                                        Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                </p>
                                                <div class="cart">
                                                    <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                </div>-->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="our-testimonial-area row">
                                <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                    <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Plug Gauges</h2>
                                    <h4 style=" margin:5px;padding-bottom:10px;">
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b>Our Plug Gauges are manufactured to relevant I.S., B.S. and DIN standards based on customer requests. We
                                            also specialize in non-standard designs such as extra lengths, multiple diameters, air grooves, pilots, depth
                                            steps and relieved NO-GO ends. Our steel gauges can be provided with hard chrome plating for prolongs
                                            gauge life.
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Reversible type (Option):</b> 5 to 12 mm
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Taper lock type:</b> IS 6137:1983-12 to 40 mm
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Double end disc type:</b> IS 6244:1980-60 to 85
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>S/E disc type:</b> IS 6244:1980 - 60 to 85 mm
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Carbide:</b>Carbide Plug gauge up to 20mm diameter.
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Accuracy:</b>Accuracy as per IS 3455:1971 and BS 969 – 195
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Material:</b> OHNS / HCHCR / EN31 / Carbide
                                            <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Hardness:</b> 55–58 HRC &amp; 58-60 HRC


                                    </h4>
                                </div>
                                <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                    <div *ngFor="let product of plugGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                        <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                            <div class="image" style="width:100%;">
                                                <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                            </div>
                                            <div class="caption">
                                                <h4 style="padding-top:10px;">
                                                    <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                </h4>
                                                <div class="description">
                                                    Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                </div>
                                                <div class="wishlist">
                                                    <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                </div>
                                                <div class="compare">
                                                    <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                </div>
                                                <!--<p class="price">
                                                        Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                </p>
                                                <div class="cart">
                                                    <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                </div>-->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Ring Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                                <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b>We are engaged in manufacturing a wide range of precisely engineered Plain Ring Gauges.
                                                <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Steel Ring Gauges:</b>Inner diameter 10mm to 200mm.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of ringGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;height:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                    
                 
                      




                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Taper Plug Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                                <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b>Standard Taper Plug Gauges.
                                                <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Range:</b>ISO Taper Pluges &amp; Rings
                                                <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Range:</b>ISO 30, ISO 40, ISO 45 and ISO 50
                                                <b style="font-size:20px;color:#ffc107;">&#8226;&nbsp;</b><b>Specifications:</b>IS 9475: 1986
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of taperPlugGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>





                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Position Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                                We manufacture special gauges as per customer's requirements such as concentricity, Flush
                                                pin, Position gauges, Receiver gauges, Carbide Gauges etc.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of positionGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>



                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Angle Checking Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                                Angle Checking Gauges.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of angleCheckingGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>




                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Clamp Hole Position Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                            Clamp Hole Position Gauges.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of clampHolePositionGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>







                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Crank Assy Height Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                            Crank Assy Height Gauges.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of crankAssyHeightGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>




                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Crank Assy Hole Pitch Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                            Crank Assy Hole Pitch Gauges.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of crankAssyHolePitchGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>





                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Distance Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                            Distance Gauges.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of distanceGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>




                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Hole Position Distance Checking Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                            Hole Position Distance Checking Gauges.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of holePositionDistanceCheckingGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>





                                <div class="our-testimonial-area row">
                                    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                                        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Spacer Hole Pitch Profile Gauges</h2>
                                        <h4 style=" margin:5px;padding-bottom:10px;">
                                            Spacer Hole Pitch Profile Gauge.
                                        </h4>
                                    </div>
                                    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                                        <div *ngFor="let product of spacerHolePitchProfileGauges"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" style="min-width:250px;margin:5px;">
                                            <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                                <div class="image" style="width:100%;">
                                                    <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                                </div>
                                                <div class="caption">
                                                    <h4 style="padding-top:10px;">
                                                        <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                                    </h4>
                                                    <div class="description">
                                                        Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                                    </div>
                                                    <div class="wishlist">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                                    </div>
                                                    <div class="compare">
                                                        <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                                    </div>
                                                    <!--<p class="price">
                                                            Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                                    </p>
                                                    <div class="cart">
                                                        <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>




                    <ngx-smart-modal #myBootstrapModal identifier="myBootstrapModal" [closable]="false">
                        <div id="bootstrap-modal">
                            <div class="modal-header" >
                                <h5 class="modal-title "><span style="font-weight:Bold !important;font-size: 14px !important;">Product</span></h5>
                                <button type="button" class="close" data-dismiss="modal"  aria-label="Close" (click)="myBootstrapModal.close()">
                                <span aria-hidden="true" style="font-weight:Bold !important;font-size:24px !important;">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <img src="{{imageURL}}" class="img-fluid rounded" style="width:100%">
                                <!--<span aria-hidden="true">Image Display </span>-->
                            </div>
                            <div class="modal-footer" >
                                <div style="width:100%;text-align:right;">
                                        <input type="button" class="btn btn-default btn-send" (click)="myBootstrapModal.close()" value="Close" style="margin-right: 10px;">
                                </div>
                            </div>
                        </div>
                    </ngx-smart-modal>























    
                    </div>
                </div>
              </div>
                </div>
              </div>
  
  
  
  
  
  
  
  
  
  
  
  </div>
  <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-footer></app-footer>
      </div>
   </div>
</div>






