<div class="container" style="background-color:#FFF;">
  <app-header></app-header>
  <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-menu></app-menu>
      </div>
   </div>
  <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
      



        <div id="column-left" class="col-sm-3 hidden-xs">
            <app-common-left-panel></app-common-left-panel>
        </div>
    
    
    
    
    
     
        <div id="content" class="col-sm-9"><ul class="breadcrumb">
            <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
            <li><a  routerLink="/jigs-and-fixtures.html">Jigs And Fixtures</a></li>
            </ul>
            <!--<h1>Downloads</h1>-->
            <div>
                  <img src="/assets/images/banners/jigs-and-fixtures.jpg" class="img-responsive">
            </div><br/><br/>
            <div class="panel">
              <div class="panel-body">
                  <div class="row">
                   <div class="contact_info">
                      
    


                    <div class="our-testimonial-area row">
                        <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
                            <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Jigs &amp; Fixtures</h2>
                            <h4 style=" margin:5px;padding-bottom:10px;">
                                Jigs &amp; Fixtures
                            </h4>
                        </div>
                        <div id="machinaries" class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
                            <div *ngFor="let product of jigsAndFixtures"  class="product-layout product-grid col-lg-3 col-md-3 col-sm-6 col-xs-6" >
                                <div class="product-thumb product-block"style="width:100%;height:250px;cursor:pointer;" (click)="setImageURL(product.url)">
                                    <div class="image" style="width:100%;">
                                        <img src="{{product.url}}" alt="Snap Gauge" title="{{product.caption}}" class="img-responsive" style="width:100%;height:200px;">
                                    </div>
                                    <div class="caption">
                                        <h4 style="padding-top:10px;">
                                            <span  (click)="setImageURL(product.url)" title="Snap Gauge">{{product.caption}}</span>
                                        </h4>
                                        <div class="description">
                                            Stop your co-workers in their tracks with the stunning new 30-inch diagonal HP LP3065 Flat Panel.
                                        </div>
                                        <div class="wishlist">
                                            <button type="button" data-toggle="tooltip" title="" onclick="wishlist.add('47');" data-original-title="Add to Wish List"><i class="fa fa-heart"></i>Add to Wish List</button>
                                        </div>
                                        <div class="compare">
                                            <button type="button" data-toggle="tooltip" title="" onclick="compare.add('47');" data-original-title="Compare this Product"><i class="fa fa-exchange"></i>Compare this Product</button>
                                        </div>
                                        <!--<p class="price">
                                                Rs 122.00<span class="price-tax">Ex Tax: $100.00</span>
                                        </p>
                                        <div class="cart">
                                            <button type="button" onclick="cart.add('47');"> <span class="hidden-xs hidden-sm hidden-md">Add to Cart</span></button>
                                        </div>-->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <ngx-smart-modal #myBootstrapModal identifier="myBootstrapModal" [closable]="false">
                        <div id="bootstrap-modal">
                            <div class="modal-header" >
                                <h5 class="modal-title "><span style="font-weight:Bold !important;font-size: 14px !important;">Jigs &amp; Fixtures</span></h5>
                                <button type="button" class="close" data-dismiss="modal"  aria-label="Close" (click)="myBootstrapModal.close()">
                                <span aria-hidden="true" style="font-weight:Bold !important;font-size:24px !important;">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <img src="{{imageURL}}" class="img-fluid rounded" style="width:100%">
                                <!--<span aria-hidden="true">Image Display </span>-->
                            </div>
                            <div class="modal-footer" >
                                <div style="width:100%;text-align:right;">
                                        <input type="button" class="btn btn-default btn-send" (click)="myBootstrapModal.close()" value="Close" style="margin-right: 10px;">
                                </div>
                            </div>
                        </div>
                    </ngx-smart-modal>












    
                    </div>
                </div>
              </div>
                </div>
              </div>
  
  
  
  
  
  
  
  
  
  
  
  </div>
  <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-footer></app-footer>
      </div>
   </div>
</div>

