<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/order-information.html">Order Information</a></li>
              </ul>
              
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">











                        <div id="content" class="col-sm-9">
                          <h2>Order Information</h2>
                          <table class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <td class="text-left" colspan="2">Order Details</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-left" style="width: 50%;">              <b>Order ID:</b> #145<br>
                                  <b>Date Added:</b> 08/09/2019</td>
                                <td class="text-left">              <b>Payment Method:</b> Cash On Delivery<br>
                                                              <b>Shipping Method:</b> Flat Shipping Rate              </td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <td class="text-left" style="width: 50%;">Payment Address</td>
                                            <td class="text-left">Shipping Address</td>
                                          </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-left">amengineers amengineers<br>Am Engineers<br>amengineers<br>chennai 6000048<br>Tamil Nadu<br>India</td>
                                            <td class="text-left">amengineers amengineers<br>Am Engineers<br>amengineers<br>chennai 6000048<br>Tamil Nadu<br>India</td>
                                          </tr>
                            </tbody>
                          </table>
                          <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <td class="text-left">Product Name</td>
                                  <td class="text-left">Model</td>
                                  <td class="text-right">Quantity</td>
                                  <td class="text-right">Price</td>
                                  <td class="text-right">Total</td>
                                                <td style="width: 20px;"></td>
                                              </tr>
                              </thead>
                              <tbody>
                                            <tr>
                                  <td class="text-left">Arcu vitae imperdiet                                <br>
                                    &nbsp;<small> - Delivery Date: 2011-04-22</small>
                                    </td>
                                  <td class="text-left">Product 21</td>
                                  <td class="text-right">1</td>
                                  <td class="text-right">$100.00</td>
                                  <td class="text-right">$100.00</td>
                                  <td class="text-right" style="white-space: nowrap;">                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/order/reorder&amp;order_id=145&amp;order_product_id=167" data-toggle="tooltip" title="" class="btn btn-primary" data-original-title="Reorder"><i class="fa fa-shopping-cart"></i></a>
                                                    <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/return/add&amp;order_id=145&amp;product_id=47" data-toggle="tooltip" title="" class="btn btn-danger" data-original-title="Return"><i class="fa fa-reply"></i></a></td>
                                </tr>
                                                      </tbody>
                              <tfoot>
                                            <tr>
                                  <td colspan="3"></td>
                                  <td class="text-right"><b>Sub-Total</b></td>
                                  <td class="text-right">$100.00</td>
                                                <td></td>
                                              </tr>
                                            <tr>
                                  <td colspan="3"></td>
                                  <td class="text-right"><b>Flat Shipping Rate</b></td>
                                  <td class="text-right">$5.00</td>
                                                <td></td>
                                              </tr>
                                            <tr>
                                  <td colspan="3"></td>
                                  <td class="text-right"><b>Total</b></td>
                                  <td class="text-right">$105.00</td>
                                                <td></td>
                                              </tr>
                                          </tfoot>
                            </table>
                          </div>
                                      <h3>Order History</h3>
                          <table class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <td class="text-left">Date Added</td>
                                <td class="text-left">Order Status</td>
                                <td class="text-left">Comment</td>
                              </tr>
                            </thead>
                            <tbody>
                                        <tr>
                                <td class="text-left">08/09/2019</td>
                                <td class="text-left">Pending</td>
                                <td class="text-left"></td>
                              </tr>
                                      </tbody>
                          </table>
                                <div class="buttons clearfix">
                            <div class="pull-right"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/order" class="btn btn-primary">Continue</a></div>
                          </div>
                          </div>













                  </div>
                </div>
                  </div>
                </div>
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  