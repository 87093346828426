<div class="our-testimonial-area row">
    <div class="our-testimonial-tittle wow fadeInUp" style="width:100%;visibility: visible; animation-name: fadeInUp;background: rgba(93, 173, 226, 0.5);">
        <h2 class="wow fadeInDown animated animated" style="animation-name:fadeInDown;">Our TestimonialS</h2>
        <h4 style=" margin:5px;padding-bottom:10px;">Our clients praise us for our great service, expert knowledge and on-time delivery. Few of those are given here.</h4>
    </div>
    <div class="our-testimonial-carosel" style="width:100% !important; padding-left:10px;padding-right:10px;">
            <swiper  [config]="config">
                <div *ngFor="let slide of slides">
                        <div style="width:100%;">
                                <div class="item">
                                    <div class="media">
                                        <div class="media-left">
                                            <a href="#">
                                                <img class="media-object" src="{{slide.url}}" alt="">
                                            </a>
                                        </div>
                                        <div class="media-body">
                                            <h4 class="media-heading">{{slide.name}}</h4>
                                            <h6>{{slide.designation}}, {{slide.company}}</h6>
                                        </div>
                                    </div>
                                    <p><i class="fa fa-quote-right" aria-hidden="true"></i>
                                        {{slide.message}}
                                        <i class="fa fa-quote-left" aria-hidden="true"></i>
                                    </p>
                                </div>
                        </div>

                </div>
            </swiper>
      
    </div>
</div>