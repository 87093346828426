import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-jigs-and-fixtures',
  templateUrl: './jigs-and-fixtures.component.html',
  styleUrls: ['./jigs-and-fixtures.component.scss']
})
export class JigsAndFixturesComponent implements OnInit {

  imageURL =''
  imageCaption = ''
  public jigsAndFixtures = [
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture1.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture2.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture3.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture4.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture5.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture6.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture7.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture8.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture9.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture10.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture11.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' },
    { url: '/assets/images/products/jigs-and-fixtures/spring-checking-fixture12.jpg', caption: 'Spring Checking Fixture', href: 'https://amengineers.in/' }
  ];
  
  constructor(public ngxSmartModalService: NgxSmartModalService) { }

 

  ngOnInit() {
  }

  setImageURL(url){
    this.imageURL = url;
    this.ngxSmartModalService.getModal('myBootstrapModal').open();
  }

  ngAfterViewInit() {
    const obj: Object = {
      prop1: 'test',
      prop2: true,
      prop3: [{a: 'a', b: 'b'}, {c: 'c', d: 'd'}],
      prop4: 327652175423
    };

    this.ngxSmartModalService.setModalData(obj, 'myModal');
  }

}
