import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SwiperModule, SwiperConfigInterface,SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AuthService } from './common/services/auth.service';
import { AuthGuard } from './common/auth-guard/auth.guard';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap'
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './common/menu/menu.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { CareersComponent } from './pages/careers/careers.component';
import { HomeComponent } from './pages/home/home.component';
import { ConstantsService } from './common/services/constants.service';

import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbComponent } from './common/breadcrumb/breadcrumb.component';
import { DatepickerMultipleComponent } from './common/datepicker-multiple/datepicker-multiple.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HistoryComponent } from './pages/history/history.component';
import { VisionAndMissionComponent } from './pages/vision-and-mission/vision-and-mission.component';
import { WallOfGratitudeComponent } from './pages/wall-of-gratitude/wall-of-gratitude.component';
import { QualityPolicyComponent } from './pages/quality-policy/quality-policy.component';
import { ProductsComponent } from './pages/product/products/products.component';
import { ProductInformationComponent } from './pages/product/product-information/product-information.component';
import { DownloadsComponent } from './pages/downloads/downloads.component';
import { VideosComponent } from './pages/videos/videos.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { LeftPanelComponent } from './common/left-panel/left-panel.component';
import { CommonLeftPanelComponent } from './common/common-left-panel/common-left-panel.component';
import { OurOfferingsComponent } from './common/our-offerings/our-offerings.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { SwipperComponent } from './common/swipper/swipper.component';
import { OurContactComponent } from './common/our-contact/our-contact.component';
import { OurClientsComponent } from './common/our-clients/our-clients.component';
import { OurTestimonialsComponent } from './common/our-testimonials/our-testimonials.component';
import { GaugesComponent } from './pages/product/product-list/gauges/gauges.component';
import { JigsAndFixturesComponent } from './pages/product/product-list/jigs-and-fixtures/jigs-and-fixtures.component';
import { AccessoriesComponent } from './pages/product/product-list/accessories/accessories.component';
import { MachineriesComponent } from './pages/machineries/machineries.component';
import { ModalWindowComponent } from './common/modal-window/modal-window.component';
import { LoginComponent } from './pages/account/login/login.component';
import { RegisterSuccessComponent } from './pages/account/register-success/register-success.component';
import { LogoutComponent } from './pages/account/logout/logout.component';
import { RegisterComponent } from './pages/account/register/register.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CheckoutSuccessComponent } from './pages/checkout-success/checkout-success.component';

import { MyAccountComponent } from './pages/account/my-account/my-account.component';
import { ForgottenPasswordComponent } from './pages/account/forgotten-password/forgotten-password.component';
import { EditAccountComponent } from './pages/account/edit-account/edit-account.component';
import { AddressBookComponent } from './pages/account/address-book/address-book.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { OrderHistoryComponent } from './pages/account/order-history/order-history.component';
import { OrderInformationComponent } from './pages/account/order-information/order-information.component';
import { WishListComponent } from './pages/account/wish-list/wish-list.component';
import { RewardPointsComponent } from './pages/account/reward-points/reward-points.component';
import { ReturnsComponent } from './pages/account/returns/returns.component';
import { TransactionsComponent } from './pages/account/transactions/transactions.component';
import { NewsletterComponent } from './pages/account/newsletter/newsletter.component';
import { RecurringPaymentsComponent } from './pages/account/recurring-payments/recurring-payments.component';
import { EditAddressComponent } from './pages/account/edit-address/edit-address.component';
import { AccountLeftPanelComponent } from './pages/account/account-left-panel/account-left-panel.component';
import { ProductReturnComponent } from './pages/account/product-return/product-return.component';






const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    CareersComponent,
    HomeComponent,
    BreadcrumbComponent,
    DatepickerMultipleComponent,
    AboutUsComponent,
    HistoryComponent,
    VisionAndMissionComponent,
    WallOfGratitudeComponent,
    QualityPolicyComponent,
    ProductsComponent,
    DownloadsComponent,
    VideosComponent,
    ContactUsComponent,
    SitemapComponent,
    LeftPanelComponent,
    CommonLeftPanelComponent,
    OurOfferingsComponent,
    ClientsComponent,
    SwipperComponent,
    OurContactComponent,
    OurClientsComponent,
    OurTestimonialsComponent,
    GaugesComponent,
    JigsAndFixturesComponent,
    AccessoriesComponent,
    MachineriesComponent,
    ModalWindowComponent,
    LoginComponent,
    RegisterComponent,
    RegisterSuccessComponent,
    LogoutComponent,
    RegisterComponent,
    MyAccountComponent,
    ForgottenPasswordComponent,
    EditAccountComponent,
    AddressBookComponent,
    ChangePasswordComponent,
    OrderHistoryComponent,
    WishListComponent,
    RewardPointsComponent,
    ReturnsComponent,
    TransactionsComponent,
    NewsletterComponent,
    RecurringPaymentsComponent,
    EditAddressComponent,
    AccountLeftPanelComponent,
    ShoppingCartComponent,
    CheckoutComponent,
    CheckoutSuccessComponent,
    OrderInformationComponent,
    ProductReturnComponent,
    ProductInformationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    SwiperModule,
    NgxSmartModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA8i7AGR1UtH1n9iUyHcVC-qqZ8NzVcpYQ',
      libraries: ["places"]})
      
  ],
  providers: [ConstantsService,
    AuthGuard,
    AuthService,
    {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  }],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
