
<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        
  
  
  
          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/login.html">Login</a></li>
              </ul>
              <!--<h1>Downloads</h1>-->
              <div class="panel">
                <div class="panel-body">
                    <div class="row">
  
  

  
  
                            <div class="col-sm-6">
                              <div class="well">
                                <h2>New Customer</h2>
                                <p><strong>Register Account</strong></p>
                                <p>By creating an account you will be able to shop faster, be up to date on an order's status, and keep track of the orders you have previously made.</p>
                                <a href="#/user-register.html" class="btn btn-primary">Continue</a></div>
                            </div>
                            <div class="col-sm-6">
                              <div class="well">
                                <h2>Returning Customer</h2>
                                <p><strong>I am a returning customer</strong></p>
                                <form [formGroup]="form" (ngSubmit)="login()">
                                  <div class="form-group">
                                    <label class="control-label" for="input-email">E-Mail Address</label>
                                    <input type="text" name="email" value="" placeholder="E-Mail Address" id="input-email" class="form-control" formControlName="email" >
                                  </div>
                                  <div class="form-group">
                                    <label class="control-label" for="input-password">Password</label>
                                    <input type="password" name="password" value="" placeholder="Password" id="input-password" class="form-control" formControlName="password" >
                                    <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/forgotten">Forgotten Password</a></div>
                                    <button type="submit" value="Login" class="btn btn-primary"></button>
                                </form>
                              </div>
                            </div>
  
  
  
  
  
  
  
  
  
  

  
                  </div>
                </div>
                  </div>
                </div>
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  