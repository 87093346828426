import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  readonly distFolderLocation: string = "/amengineers/dist/amengineers-angular";
  //readonly distFolderLocation: string = "/amengineers/dist/amengineers-angular";
  readonly baseAppUrl: string = 'http://localhost:57431/';
  //readonly baseAPIUrl: string = 'https://api.cheapatom.com:4443/users';
  //readonly baseAPIUrl: string = 'http://localhost:3000/users';
  readonly baseAPIUrl: string = 'http://localhost:3000';
  constructor() { }
}
