<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
     
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/wallOfGratitude.html">Wall Of Gratitude</a></li>
              </ul>
              <!--<h1>Wall Of Gratitude</h1>-->
              <div>
                <img src="/assets/images/banners/gratitude-banner.jpg" class="img-responsive">
              </div>
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                     <div class="contact_info" style="font-size: 14px;" align="justify">
                      
                        “My words can never be enough to praise your actions because your work always meets my expectations. Thank you."<br/>
                        “Thank you for being so committed. It’s only a matter of time that all your efforts will be duly remitted.”<br/>
                        “Thank you for bringing your positive attitude to work every day. Projects become easier to execute, changes become easier to implement and problems become easier to solve.”<br/>
                        “My words can never be enough to praise your actions because your work always meets my expectations. Thank you.”<br/>
      
                      </div>
                  </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  