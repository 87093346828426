import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vision-and-mission',
  templateUrl: './vision-and-mission.component.html',
  styleUrls: ['./vision-and-mission.component.scss']
})
export class VisionAndMissionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
