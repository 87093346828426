<div class="container" style="background-color:#FFF;">
  <app-header></app-header>
  <div class="row" style="padding-top:2px;">
    <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
        <app-menu></app-menu>
    </div>
 </div>
  <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
   
      

        <div id="column-left" class="col-sm-3 hidden-xs">
            <app-common-left-panel></app-common-left-panel>
        </div>
    
    
  
        <div id="content" class="col-sm-9"><ul class="breadcrumb">
            <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
            <li><a  routerLink="/clients.html">Clients</a></li>
            </ul>
            <!--<h1>Enquery</h1>-->
            <div>
                  <img src="/assets/images/banners/clients-banner.png" class="img-responsive">
            </div><br/><br/>
            <div class="panel">
              <div class="panel-body" style="font-size: 14px;" align="justify">
                    
    
              <div>      
                <strong>Our Valuable Customers:</strong><br/>
                <ul>
                  <li>M/s. Ford India Pvt. Ltd. – S.P Kovil Chennai.</li>
                  <li>M/s. Sundaram Fasteners Ltd. – SEZ Unit. Mahindra City.</li>
                  <li>M/s. Motherson Sintermetal Technology Ltd. – Pondicherry.</li>
                  <li>M/s. Green Tech Industries Ltd. – Naidupet, Andrapradesh.</li>
                  <li>M/s. ILJIN Automotive Pvt. Ltd.- Irrungattukottai.</li>
                  <li>M/s. Delphi Tvs Ltd. – Oragadam, Chennai.</li>
                  <li>M/s. MM Forgings Ltd.</li>
                  <li>M/s. Venu Engineering Service - Chennai</li>
                </ul>
         
              </div> <br/><br/>

                <swiper  [config]="config" style="overflow: hidden;">
                    <div *ngFor="let slide of slides" style="background-color: white;opacity:1;" >
                      <div style="background-color: white;opacity:1;">
                        <img src="{{slide}}" class="img-responsive" style="background-color: white;opacity:1;">
                      </div>
                    </div>
                </swiper>
            <!--
              <br/><br/><br/><br/><br/><br/><br/><br/>
                <div id="Div1" class="carousel slide" data-interval="2500" data-ride="carousel" data-pause="hover" data-wrap="true">


                  <div class="carousel-inner">
                      <div class="item active left">
                          <div class="row">
                              <div class="col-xs-3">
                                  <img src="/assets/images/clients/delphi-tvs.jpg" class="img-responsive">
                                  <div class="carousel-caption">
                               
                                  </div>
                              </div>
                              <div class="col-xs-3">
                                  <img src="/assets/images/clients/ford.jpg" class="img-responsive">
                                  <div class="carousel-caption">
                                 
                                  </div>
                              </div>
                               <div class="col-xs-3">
                                  <img src="/assets/images/clients/greentech.jpg" class="img-responsive">
                                  <div class="carousel-caption">
                                    
                                  </div>
                              </div>
                              <div class="col-xs-3">
                                  <img src="/assets/images/clients/mm-forgings.jpg" class="img-responsive">
                                  <div class="carousel-caption">
                                    
                                  </div>
                              </div>
                              
                          </div>
                      </div>
                      <div class="item next left">
                          <div class="row">
                          
                              <div class="col-xs-3">
                                  <img src="/assets/images/clients/motherson.png" class="img-responsive">
                                  <div class="carousel-caption">
                                  
                                  </div>
                              </div>
                              <div class="col-xs-3">
                                  <img src="/assets/images/clients/tvs.png" class="img-responsive">
                                  <div class="carousel-caption">
                                  
                                  </div>
                              </div>
                              <div class="col-xs-3">
                                  <img src="/assets/images/clients/venu-engineering-services.png" class="img-responsive">
                                  <div class="carousel-caption">
                                    
                                  </div>
                              </div>
                               <div class="col-xs-3">
                                  <img src="/assets/images/clients/iljin.jpg" class="img-responsive">
                                  <div class="carousel-caption">
                                  
                                  </div>
                              </div>
                            
                          </div>
                      </div>
                     
                      
                   
                  </div>
  
                  <a href="#Div1" class="carousel-control left" data-slide="prev">
                      <span class="glyphicon glyphicon-chevron-left"></span>
                  </a>
                  <a href="#Div1" class="carousel-control right" data-slide="next">
                      <span class="glyphicon glyphicon-chevron-right"></span>
                  </a>
              </div>-->

























              </div>
                </div>
              </div>
  
  
  
  
  
  
  
  
  
  
  
    
  </div>
  <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-footer></app-footer>
      </div>
   </div>
</div>

