<div class="container" style="background-color:#FFF;">
        <app-header></app-header>
        <div class="row" style="padding-top:2px;">
            <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
                <app-menu></app-menu>
            </div>
         </div>
        <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
          
            
    
    
    
              <div id="column-left" class="col-sm-3 hidden-xs">
                  <app-common-left-panel></app-common-left-panel>
              </div>
          
          
          
          
          
           
              <div id="content" class="col-sm-9"><ul class="breadcrumb">
                  <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
                  <li><a  routerLink="/videos.html">Videos</a></li>
                  </ul>
                  <!--<h1>Videos</h1>-->
                  <div>
                    <img src="/assets/images/banners/videos-banner.jpg" class="img-responsive">
                  </div><br/><br/>
                  <div class="panel panel-default">
                    <div class="panel-body">
                        <div class="row">
                         <div class="contact_info">
                          
          
    
    
    
    
    
    
    
    
    
                            <section id="middle_content" role="main">
                                <div class="entry">
                                </div>
                                <div style="float: left; margin: 0px 30px 0px 30px;">
                                    <h3><strong>AM Engineers Machineries</strong></h3>
                                    <p><iframe src="https://www.youtube.com/embed/qnxN_lKjCMg" allowfullscreen="allowfullscreen" width="350" height="250" frameborder="0"><br />
                                    </iframe></p>
                                </div>
                                <div style="float: left; margin: 0px 30px 0px 30px;">
                                    <h3><strong>AM Engineers, Position Gauge</strong></h3>
                                    <p><iframe src="https://www.youtube.com/embed/9v9gqRG9OaI" allowfullscreen="allowfullscreen" width="350" height="250" frameborder="0"><br />
                                    </iframe></p>
                                </div>
                                <div style="float: left; margin: 0px 30px 0px 30px;">
                                    <h3><strong>AM Engineers, Clamp Hole Position Gauge</strong></h3>
                                    <p><iframe src="https://www.youtube.com/embed/F5T37Xlbwpc" allowfullscreen="allowfullscreen" width="350" height="250" frameborder="0"><br />
                                    </iframe></p>
                                </div>
                                <div class="cmsms_cc">
                              <div class="one_third" data-folder="column" data-type=""></div>
                                  </div>
                                </section>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
          
                          </div>
                      </div>
                    </div>
                      </div>
                    </div>
        
        
        
        
        
        
        
        
        
        
        
         
        </div>
        <div class="row" style="padding-top:2px;">
            <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
                <app-footer></app-footer>
            </div>
         </div>
      </div>
      
      