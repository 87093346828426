<footer>
    <div id="footer" class="container">
       <div class="row">
      <div id="powered"></div> 
      <div id="bottomfooter">
        <ul class="list-unstyled">
          <li class="first"><a routerLink="/">Home</a></li>
          <li><a routerLink="/aboutUs.html">About Us</a></li>   
          <li><a routerLink="/products/gauges.html">Products</a></li>   
          <li><a routerLink="/downloads.html">Downloads</a></li>    
          <li><a routerLink="/careers.html">Careers</a></li>     
          <li><a routerLink="/sitemap.html">Site Map</a></li> 		
          <li class="last"><a routerLink="/contactUs.html">Contact Us</a></li> 
        </ul><br/>
        <span>Copyright 2019 © AM Engineers. All rights reserved.</span>
        <div class="social-media-link">
            <span><a href="/" class="btn-floating btn-lg btn-fb"><i class="fa fa-facebook"></i></a></span>
            <span><a href="/" class="btn-floating btn-lg btn-li"><i class="fa fa-linkedin"></i></a></span>
            <span><a href="/" class="btn-floating btn-lg btn-tw"><i class="fa fa-twitter"></i></a></span>
              <span><a href="/" class="btn-floating waves-effect btn-lg btn-gplus"><i class="fa fa-google-plus"></i></a></span>
              <span><a href="/" class="btn-floating btn-lg btn-yt"><i class="fa fa-youtube"></i></a></span>
       </div>
      </div>
      <div style="float:right;text-align: right;width:100%;">Powered By <a href="https://www.mollaservices.com/" style="color:black;margin-right:10px;">mollaservices.com</a></div> 
    </div>
    </div>
  </footer>