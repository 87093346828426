<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-account-left-panel></app-account-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/my-account.html">My Account</a></li>
              </ul>
              <!--<h1>Downloads</h1>-->
              <div class="panel">
                <div class="panel-body">
                    <div class="row">












                        <div id="content" class="col-sm-9">
                          <h2>My Account</h2>
                         <div class="content">
                        <ul class="list-unstyled">
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/edit">Edit your account information</a></li>
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/password">Change your password</a></li>
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/address">Modify your address book entries</a></li>
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/wishlist">Modify your wish list</a></li>
                          </ul>
                        </div>
                          <h2>My Orders</h2>
                         <div class="content">
                          <ul class="list-unstyled">
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/order">View your order history</a></li>
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/download">Downloads</a></li>
                                    <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/reward">Your Reward Points</a></li>
                                    <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/return">View your return requests</a></li>
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/transaction">Your Transactions</a></li>
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/recurring">Recurring payments</a></li>
                          </ul>
                        </div>
                        
                          <h2>Newsletter</h2>
                           <div class="content">
                        <ul class="list-unstyled">
                            <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/newsletter">Subscribe / unsubscribe to newsletter</a></li>
                          </ul>
                        </div>
                          </div>













                  </div>
                </div>
                  </div>
                </div>
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  