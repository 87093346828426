import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  submitted = false;
  isSuccess = false;
  enqueryForm: FormGroup;

  constructor() { }

  ngOnInit() {
  }
  resetForm(form: any) {
    this.submitted = false;
    this.isSuccess = false;
    form.reset();
  }

  submitForm(form: any) {
  }
}
