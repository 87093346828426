<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-account-left-panel></app-account-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/account/edit-account.html">Edit Account</a></li>
              </ul>
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                    












                            <div id="content" class="col-sm-9"> <h1>My Account Information</h1>
                                  <form action="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/edit" method="post" enctype="multipart/form-data" class="form-horizontal">
                                    <fieldset>
                                      <legend>Your Personal Details</legend>
                                      <div class="form-group required">
                                        <label class="col-sm-2 control-label" for="input-firstname">First Name </label>
                                        <div class="col-sm-10">
                                          <input type="text" name="firstname" value="amengineers" placeholder="First Name" id="input-firstname" class="form-control">
                                                      </div>
                                      </div>
                                      <div class="form-group required">
                                        <label class="col-sm-2 control-label" for="input-lastname">Last Name</label>
                                        <div class="col-sm-10">
                                          <input type="text" name="lastname" value="amengineers" placeholder="Last Name" id="input-lastname" class="form-control">
                                                      </div>
                                      </div>
                                      <div class="form-group required">
                                        <label class="col-sm-2 control-label" for="input-email">E-Mail</label>
                                        <div class="col-sm-10">
                                          <input type="email" name="email" value="amengineers@gmail.com" placeholder="E-Mail" id="input-email" class="form-control">
                                                      </div>
                                      </div>
                                      <div class="form-group required">
                                        <label class="col-sm-2 control-label" for="input-telephone">Telephone</label>
                                        <div class="col-sm-10">
                                          <input type="tel" name="telephone" value="9865453450" placeholder="Telephone" id="input-telephone" class="form-control">
                                                      </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="col-sm-2 control-label" for="input-fax">Fax</label>
                                        <div class="col-sm-10">
                                          <input type="text" name="fax" value="" placeholder="Fax" id="input-fax" class="form-control">
                                        </div>
                                      </div>
                                              </fieldset>
                                    <div class="buttons clearfix">
                                      <div class="pull-left"><a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=account/account" class="btn btn-default">Back</a></div>
                                      <div class="pull-right">
                                        <input type="submit" value="Continue" class="btn btn-primary">
                                      </div>
                                    </div>
                                  </form>
                                  </div>
































































































                    </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  