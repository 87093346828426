<div class="box category">
    <div class="box-heading">Categories</div>
    <div class="box-content ">
        <ul class="box-category treeview-list treeview">
            <li class="expandable"><div class="hitarea expandable-hitarea"></div>
              <a routerLink="/products/gauges.html">Gauges (13)</a>
            </li>
            <li class="expandable"><div class="hitarea expandable-hitarea"></div>
              <a routerLink="/products/jigs-and-fixtures.html">Jigs And Fixtures (5)</a>
            </li>
            <li class="expandable"><div class="hitarea expandable-hitarea"></div>
              <a routerLink="/products/accessories.html" >Accessories (2)</a>
            </li>
            <li>
              <a  routerLink="/machineries.html">Machineries (1)</a>
            </li>
          </ul>	

      <!---
      <ul class="box-category treeview-list treeview">
          <li class="expandable"><div class="hitarea expandable-hitarea"></div>
            <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20">Limit Gauges (13)</a>
            <ul style="display: none;">
              <li>
                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20_59">Mac (0)</a>
              </li>
              <li>
                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20_61">PC (0)</a>
              </li>
              <li>
                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20_26">Tablets (0)</a>
              </li>
              <li class="last">
                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=20_27">Laptop (1)</a>
              </li>
            </ul>
          </li>
          <li class="expandable"><div class="hitarea expandable-hitarea"></div>
            <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=18">Air Gauges (5)</a>
            <ul style="display: none;">
              <li>
                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=18_46">Spectacles (0)</a>
              </li>
              <li class="last">
                <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=18_45">Watches (0)</a>
              </li>
            </ul>
          </li>
          <li class="expandable"><div class="hitarea expandable-hitarea"></div>
            <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=25">Comparators (2)</a>
          </li>
          <li>
            <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=57">Multiparameters (1)</a>
          </li>
        </ul>	-->
    </div>
  </div>
  <div class="box">
    <div class="box-heading">Information</div>
      <div class="list-group information box-content">
          <a  routerLink="/aboutUs.html" class="list-group-item">About Us</a>
          <a  routerLink="/aboutUs.html" class="list-group-item">Delivery Information</a>
          <a  routerLink="/aboutUs.html" class="list-group-item">Privacy Policy</a>
          <a  routerLink="/aboutUs.html" class="list-group-item">Terms &amp; Conditions</a>
          <a  routerLink="/contactUs.html" class="list-group-item">Contact Us</a>
          <a  routerLink="/sitemap.html" class="list-group-item">Site Map</a>
      </div>
  </div>
