import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HelperService } from '../../common/services/helper.service';
import { ConstantsService } from '../../common/services/constants.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public map: any = { lat: 12.916833, lng: 80.072944, zoom:17 };
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  enquery = { name: "", phone: "", email: "", enqueryType: "",enqueryMessage: ""  };
  submitted = false;
  isSuccess = false;
  enqueryForm: FormGroup;
  constructor(private formBuilder: FormBuilder,private _httpClient: HttpClient, 
    private helperService: HelperService, 
    private _constant: ConstantsService) {
    //this.helperService.getEnqueries();
   }



  ngOnInit() {
      this.enqueryForm = this.formBuilder.group({
        name: [this.enquery.name, [Validators.required, Validators.minLength(2), Validators.maxLength(15)]],
        phone: [this.enquery.phone, [Validators.required, Validators.minLength(2), Validators.maxLength(15)]],
        email: [this.enquery.email, [Validators.required, Validators.minLength(2), Validators.maxLength(50),Validators.pattern(this.emailPattern)]],
        enqueryType: [this.enquery.enqueryType, [Validators.required,Validators.maxLength(30)]],
        enqueryMessage: [this.enquery.enqueryMessage, [Validators.required,Validators.maxLength(300)]]
      });
  }

  getRequestObject(form: any){
    return {	
      "name": form.name,
      "phone": form.phone,
      "email": form.email,
      "enquery_type": form.enqueryType,
      "enquery": form.enqueryMessage
     }
  }
  resetForm(form: any) {
    this.submitted = false;
    this.isSuccess = false;
    form.reset();
  }

  submitForm(form: any) {
    this.submitted = false;
    this.isSuccess = false;
    if (form.valid) {
        console.log(form.value)
        console.log("Request Object:::"+JSON.stringify(this.getRequestObject(form.value)));
        this._httpClient.post(this._constant.baseAPIUrl+"/saveEnquery",{headers:{'Content-Type': 'application/json'}, body:this.getRequestObject(form.value)}
         ).subscribe(success => {
           //alert("Done::"+JSON.stringify(success))
           let resp = JSON.parse(JSON.stringify(success));
           if(resp.status == "success"){
            //alert("Success")
            this.submitted = true;
            this.isSuccess = true;
            form.reset();
           } else {
            //alert("Failed")
            this.submitted = true;
            this.isSuccess = false;
           }
         },
         error => {
            //alert(error)
            this.submitted = true;
            this.isSuccess = false;
         });
        
    } else {
        this.validateForm(form);
    }
  }


  submitForm222(form: any) {
    if (form.valid) {
        console.log(form.value)
        console.log("Request Object:::"+JSON.stringify(this.getRequestObject(form.value)));
        this._httpClient.post(this._constant.baseAPIUrl+"/saveEnquery",{headers:{'Content-Type': 'application/json'}, body:this.getRequestObject(form.value)}
         ).subscribe(
           (res : any[])=>{
          console.log(JSON.stringify(res));
        });
        this.submitted = true;
        form.reset();
    } else {
        this.validateForm(form);
    }
  }

  validateForm(form: any) {
    Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            this.validateForm(control);
        }
    });
  }

  hasErrors(field: any) {
    return (this.enqueryForm.get(field).invalid && this.enqueryForm.get(field).touched && this.enqueryForm.get(field).errors);
  }

  get name() { return this.enqueryForm.get('name'); }
  get phone() { return this.enqueryForm.get('phone'); }
  get email() { return this.enqueryForm.get('email'); }
  get enqueryType() { return this.enqueryForm.get('enqueryType'); }
  get enqueryMessage() { return this.enqueryForm.get('enqueryMessage'); }

}
