
<nav class="nav-container" role="navigation">
    <div class="nav-inner">
    <!-- ======= Menu Code START ========= -->
    <!-- Opencart 3 level Category Menu-->
    <div class="container">
<div id="menu" class="main-menu">
      <div class="nav-responsive" style="display:none;"><span>Menu</span><div class="expandable"><i class="fa fa-bars" aria-hidden="true"></i>
      </div></div>
      <ul class="main-navigation">
        <li class="first-menu"> <a routerLink="/">Home</a></li>
        <li class="all-menu expandable"><div class="hitarea1 expandable-hitarea1"></div><a  routerLink="/aboutUs.html" class="">About Us <span class="main-menu-dropdown-icon">&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span></a>
          <ul>                               
            <li>   
                <a  routerLink="/aboutUs.html" class="">About Us</a>	     
            </li>
            <li>			
              <a  routerLink="/history.html" class="">History</a>						  
            </li>
            <li>			
              <a routerLink="/visionAndMission.html" class="">Visson &#38; Misson</a>						  
            </li>
            <li>			
              <a  routerLink="/wallOfGratitude.html" class="">Wall of Gratitude</a>						  
            </li>
            <li class="last">			
                <a routerLink="/qualityPolicy.html" class="">Quality Policy</a>						  
            </li>	 	
          </ul>
        </li>
        <li class="all-menu expandable"><div class="hitarea1 expandable-hitarea1"></div><a  routerLink="/products/gauges.html" class="">Products<span class="main-menu-dropdown-icon">&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span></a>
            <ul>                                
              <li>
                  <a  routerLink="/products/gauges.html" class="">Gauges</a>	      
              </li>  
              <li>
                <a  routerLink="/products/jigs-and-fixtures.html" class="">Jigs & Fixtures</a>	      
              </li>  
              <!--<li>
                  <a  routerLink="/products/airGauges.html" class="activSub">Air Gauges</a> 
                  <ul class="col4">
                      <li><a href="/plugGauges" class="">Carbide Air Plug Gauges</a></li>
                      <li><a href="/ringGauges" class="">Carbide Air Ring Gauges</a></li>
                      <li class="last"><a href="/snapauges" class="" >Air Snap Gauges</a></li>
                  </ul>     
              </li>-->                                    
              <li class="last">
                  <a routerLink="/products/accessories.html" class="">Parts & Accessories</a>	      
              </li>		
            </ul>
        </li>
        <!--
        <li class="all-menu  expandable"><div class="hitarea1 expandable-hitarea1"></div><a  routerLink="/partsAndAccessories.html" class="">Parts &amp; Accessories <span class="main-menu-dropdown-icon">&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span></a>  
            <ul>                               
              <li>
                  <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=18_46">Sample Gauges1</a>	
              </li>                                     
              <li class="last">
                  <a href="http://opencart.templatemela.com/OPC05/OPC050106/index.php?route=product/category&amp;path=18_46">Sample Gauges2</a>	
              </li>		
            </ul>
        </li>-->
        <li class="all-menu">
            <a  routerLink="/machineries.html">Machineries</a>
        </li>
        <li class="all-menu">
            <a  routerLink="/downloads.html">Downloads</a>
        </li>
        <li class="all-menu"><a routerLink="/videos.html">Videos</a></li>
        <li class="all-menu"><a routerLink="/clients.html">Clients</a></li>
        <li class="all-menu"><a  routerLink="/careers.html">Career</a></li>
        <li class="all-menu last"><a routerLink="/contactUs.html">Contact Us</a></li>
      </ul>
    </div>

    </div>
    </div>
    </nav>