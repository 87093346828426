<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <!--Font style :::::: Calibri-->
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/qualityPolicy.html">Quality Policy</a></li>
              </ul>
              <!--<h1>Quality Policy</h1>-->
              <div>
                    <img src="/assets/images/banners/quality-policy-banner.png" class="img-responsive">
              </div>
              <div class="panel">
                <div class="panel-body controls">
                    <div class="row">
                     <div class="contact_info" style="font-size:14px;"  align="justify">
                        <p style="text-indent :4em;" >AM Engineers, is committed to complete customer satisfaction and continuous improvement while delivering quality 
                        products that meet or exceed our customers expectations, on-time and at a competitive cost. It is through this 
                        commitment, we will strive to establish long lasting relationships with our customers.</p>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/images/quality-policy/manufacturing-quality.jpg" class="img-responsive">
                        </div>
                        <div class="col-md-6">
                            <img src="/assets/images/quality-policy/quality-policy.png" class="img-responsive">
                        </div>
                    </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  