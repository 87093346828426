<div class="container" style="background-color:#FFF;">
  <app-header></app-header>
  <div class="row" style="padding-top:2px;">
    <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
        <app-menu></app-menu>
    </div>
 </div>
  <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
      




        <div id="column-left" class="col-sm-3 hidden-xs">
            <app-common-left-panel></app-common-left-panel>
      </div>
  
  
  
  
  
   
      <div id="content" class="col-sm-9"><ul class="breadcrumb">
          <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
          <li><a  routerLink="/careers.html">Careers</a></li>
          </ul>
          <!--<h1>Careers</h1>-->
          <div  >
                <img src="/assets/images/banners/carriers-banner.png" class="img-responsive">
          </div>
          <div class="panel">
            <div class="panel-body">
                <div class="row">
                 <div class="contact_info" style="font-size: 14px;" align="justify">
                  
                    <p style="text-indent :4em;" >We are looking for passionate people who are intelligent, smart, ambitious, self-motivated and self-driven who can contribute innovative ideas and
                        most importantly love and enjoy what they do.We offer a cordial work environment that provides "Freedom with Accountability" encouraging
                        employees to challenge themselves every day. If you are person with right attitude, who can think out of the box and looking for a challenging
                        opportunity, check the current openings and drop us a mail.</p>
                  </div>
                  <div class=" container">
                        <div class="col-lg-12 label-primary" style="  min-height:100px; margin-bottom:20px">
                     <p style=" margin-top:2%; font-family:Times New Roman; font-weight:bold; font-size:larger;  color:White">Internship Details</p>
                    
                     <p style=" margin:20px ;font-family:Times New Roman; font-size:16px; color:White"> <i class=" fa fa-check"></i>The Selected Interns will be undergoing the internship for a duration of one month  gaining  exposure to company real time working Exposure.</p> 
                     <p style=" margin:20px ;font-family:Times New Roman;font-size:16px; color:White"><i class=" fa fa-check"></i>Intern will be handling Real Time Projects.</p>
                     <p style=" margin:20px ;font-family:Times New Roman;font-size:16px; color:White"> <i class=" fa fa-check"></i> Internship Certificate will be provided after the completion of the Internship.</p>
                    <p style=" margin:20px ;font-family:Times New Roman;font-size:16px; color:White"><i class=" fa fa-check"></i>Placement Assistance will be provded for Good Performing Interns.</p> 
                    
                      </div>
                  </div>
                  <div  class="container panel" id="registration-form" style="margin-left:10px;margin-right:10px;">
                        <div  class="controls">
                         <div class="row">
                          <div class="col-md-6">
                            <div class=" form-group">
                               <label id="fromname" title="">Name*</label>
                                <input class="form-control" name="name" placeholder="Please enter your Name *" required="required" data-error="Name is required.">
                          
                                    <div class="help-block with-errors"></div>
                              </div>
                           </div>
                           <div class="col-md-6">
                            <div class=" form-group">
                                <label id="Label2" text="">Contact No*</label>
                                <input class="form-control" name="Phone" placeholder="Please enter your Contact No *" required="required" data-error="Contact No is required.">
                                   
                                    <div class="help-block with-errors"></div>
                              </div>
                           </div>
                           </div>
                           <div class="row">
                           <div class="col-md-6">
                            <div class=" form-group">
                              <label id="Label3" text="">Academic Qualification*</label>
                               <input class="form-control" name="Qualification" placeholder="Please enter you Academic Qualification *" required="required" data-error="Academic Qualification  is required.">
                                    
                                    <div class="help-block with-errors"></div>
                              </div>
                           </div>
                           <div class="col-md-6">
                            <div class=" form-group">
                              <label id="Label4" text="">City*</label>
                                     <input class="form-control" name="City" placeholder="Please enter your City *" required="required" data-error="City Name is required.">
                                    <div class="help-block with-errors"></div>
                              </div>
                           </div>
                           </div>
                           <div class="row">
                           <div class="col-md-6">
                            <div class=" form-group">
                              <label id="Label5" title="">Address*</label>
                                     <textarea name="Address" rows="2" class="form-control" placeholder="Please enter your Address*" required="required" data-error="Address is required."></textarea>
                                    <div class="help-block with-errors"></div>
                              </div>
                           </div>
                         
                         <div class="col-md-6">
                           <div class="form-group">
                            
                             <label id="Label1">Extra Skills*</label>
                                <input class="form-control" name="ExtrSkil" placeholder="Please Fill Your Extra Skills*" required="required" data-error="Services is Required">
                          
                             <div class=" help-block with-errors"></div>
                            
                           
                           </div>
                      
                         </div>
                         
                         </div>
                         <div class="row">
                         <div class=" col-md-6">
                         </div>
                         </div>
                         <div class="row">
                          <div class="col-md-6">
                         <div class=" form-group">
                      
                                   <label id="Label7" title="">InternShip Skill*</label>
                                 <textarea name="ProgammingSkill" rows="2" class="form-control" placeholder="Please Fill The Field *" required="required" data-error="Skill is Required"></textarea>
                             <div class=" help-block with-errors"></div>
                         </div>
                         
                         </div>
                         
                            <div class="col-md-6">
                         <div class=" form-group">
                      
                                   <label id="Label6" title="">College Name*</label>
                                
                                  <input class="form-control" name="txtcollage" placeholder="Please Fill Your Collage*" required="required" data-error="Collage Name is Required">
                             <div class=" help-block with-errors"></div>
                         </div>
                         
                         </div>
                          
                         </div>
                         <div class="  row">
                         <div class=" col-lg-4">
                          <label id="Label8" title="">Year *</label>
                       <select class="form-control" style="height:32px;" name="selectyear" placeholder="Please Fill Your Collage Year*" required="required" data-error="Collage Year is Required">
                        <option value=""></option>
                       <option value="First Year">First Year</option>
                        <option value="Second Year">Second Year</option>
                        <option value="Third Year">Third Year</option>
                        <option value="Fourth Year">Final Year</option>
                       </select>
                        <div class=" help-block with-errors"></div>
                         </div>
                         </div>
                         <br>
                         
                         <div class="row">
                            <div class="col-md-12" style="text-align:right;">
                                <span *ngIf="submitted" class="response-status"><span *ngIf="isSuccess" class="success-message">Saved successfully.</span><span *ngIf="!isSuccess" class="errors-message">Failed to save.</span></span>
                                <input type="button" class="btn btn-default btn-send" (click)="resetForm(enqueryForm)" value="Reset" style="margin-right: 10px;">
                                <input type="button" class="btn btn-success btn-send" (click)="submitForm(enqueryForm)" value="Registration">
                            </div>
                         </div>
                       
                        </div>
                       
                      </div>
              </div>
            </div>
              </div>
            </div>












  </div>
  <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-footer></app-footer>
      </div>
   </div>
</div>

