<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
      
        



          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/history.html">History</a></li>
              </ul>
              <!--<h1>History</h1>-->
              <div>
                <img src="/assets/images/banners/history-banner.png" class="img-responsive">
              </div><br/><br/>
              <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                     <div class="contact_info">
                      
                        
                        

































































                        <div class="profile-body">
                            <!--Timeline-->
                            <ul class="timeline-v2">
                                <li>
                                    <time class="cbp_tmtime" datetime=""><span></span> <span>2010</span></time>
                                    <i class="cbp_tmicon rounded-x hidden-xs"></i>
                                    <div class="cbp_tmlabel">
                                        <h2>Establishment of AM Engineering</h2>
                                        <p>It was the year of establishment of AM ENGINEERING by its proprietor G. Anandababu with over 10 years experience 
                                        in Auto & Non automotive industry in Gauge design, Calibration & Quality department. Started with a small tool-room setup 
                                        with only a single machine of each type i.e. Lathe, Conventional Milling, Tool &amp; Cutter Grinding and Drilling Machine. 
                                        We used to Manufacture Fixture Spare parts &amp; carry out maintenance Job Work</p>

                                        <p>We also developed tooling for clients which they initially used to Import from different Global Locations.</p>

                                    </div>
                                </li>
                                
                            </ul>
                            <!--End Timeline-->
                        </div>
      



























































































































                      </div>
                  </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  