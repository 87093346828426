<div class="box">
    <div class="box-heading">Account</div>
    <div class="list-group information box-content">
      <a class="list-group-item" routerLink="/my-account.html">My Account</a>
      <a class="list-group-item" routerLink="/edit-account.html">Edit Account</a>
      <a class="list-group-item" routerLink="/edit-address.html">Edit Address</a>
      <a class="list-group-item" routerLink="/change-password.html">Password</a>
      <a class="list-group-item" routerLink="/address-book.html">Address Book</a>
      <a class="list-group-item" routerLink="/wish-list.html">Wish List</a>
      <a class="list-group-item" routerLink="/order-history.html">Order History</a>
      <a class="list-group-item" routerLink="/edit-account.html">Downloads</a>
      <a class="list-group-item" routerLink="/reward-points.html">Reward Points</a>
      <a class="list-group-item" routerLink="/returns.html">Returns</a>
      <a class="list-group-item" routerLink="/transactions.html">Transactions</a>
      <a class="list-group-item" routerLink="/newsletter.html">Newsletter</a>
      <a class="list-group-item" routerLink="/recurring-payments.html">Recurring Payments</a>
      <a class="list-group-item" (click)="auth.logout()">Logout</a>
    </div>
</div>    
  