import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../services/constants.service';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  distFolderLocation:string;	
  constructor(private _constant: ConstantsService,public auth: AuthService) { 
    this.distFolderLocation =this._constant.distFolderLocation;	
  }

  ngOnInit() {

  }

}
