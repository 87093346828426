<div id="ms-top">
  <ul class="nav pull-left">
    <li class="active dropdown">
      <a href="#" class="dropdown-toggle" data-toggle="dropdown">
        <img src="/assets/images/gb.png" alt="English" title="English">
      </a>
      <ul class="dropdown-menu">
        <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/en"><img src="/assets/images/gb.png" alt="English" title="English"> English</a></li>
        <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/ar"><img src="/assets/images/lb.png" alt="Arabic" title="Arabic"> Arabic</a></li>
        <li><a href="http://opencart.templatemela.com/OPC05/OPC050106/ru"><img src="/assets/images/ru.png" alt="russian" title="russian"> russian</a></li>
      </ul>
    </li>
    <li class="dropdown">
      <a href="#" class="dropdown-toggle" data-toggle="dropdown">
        Rs
      </a>
      <ul class="dropdown-menu">
        <li>€ Euro</li>
        <li>£ Pound Sterling</li>
        <li>$ US Dollar</li>
      </ul>
    </li>
  </ul>
  <ul class="nav pull-right">
    <li class="dropdown">
      <a href="properties.php?type=showall" class="dropdown-toggle" data-toggle="dropdown">
        <i class="fa fa-user"></i>
        My Account
        <span class="top_downarrow"></span>
      </a>
      <ul class="dropdown-menu">
        <li *ngIf="!auth.isLoggedIn()"><a routerLink="/register.html">Register</a></li>
        <li *ngIf="!auth.isLoggedIn()"><a routerLink="/login.html">Login</a></li>
        <li *ngIf="auth.isLoggedIn()" ><a (click)="auth.logout()">Logout</a></li>
        <li *ngIf="auth.isLoggedIn()" ><a routerLink="/my-account.html">My Account</a></li>
      </ul>
    </li>
    <li><a href="#"><i class="fa fa-heart"></i> Wish List (0)</a></li>
    <li><a routerLink="/shopping-cart.html" ><i class="fa fa-share"></i> Checkout</a></li>
  </ul>
</div>

 


  <header>
    <div class="container">
      <div class="row" style="width:100%">
       <div class="header-left">
        <div class="col-sm-12">
          <div id="logo">
            <a href="/"><img src="/assets/images/logo/logo.PNG" title="AM Engineers" alt="AM Engineers" class="img-responsive"></a>
          </div>
        </div>
      </div>
      <!--<div class="header-medium">
        
        <div id="search" class="input-group">
        <span class="input-group-btn">
          <input type="text" name="search" placeholder="Search" class="form-control input-lg">
          <button type="button" class="btn btn-default btn-lg"><i class="fa fa-search"></i></button>
        </span>
        </div>   
      </div>-->  
       <div class="header-right">
          <div class="header_html">
              <br>
              <span class="contact_widget_phone">Call us:<span style="width:1%"></span>+91 (979) 1123622</span> 
              <span class="contact_widget_email">
              <span style="width:1%"></span><br>
              <a href="mailto:info@amengineers.web">amengineers2010@gmail.com</a>, 
              <a href="mailto:info@amengineers.web">info@amengineers.in</a> <br>
              <a href="mailto:info@amengineers.web">sales@amengineers.in</a>
              </span>
         </div>
  </div>
   
    </div>
    </div>
  </header>