import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-machineries',
  templateUrl: './machineries.component.html',
  styleUrls: ['./machineries.component.scss']
})
export class MachineriesComponent implements OnInit {
  imageURL =''
  imageCaption = ''
  public plugGauges = [
    { url: '/assets/images/machinary/lathe-machine.png', caption: 'Lathe Machine', href: 'https://amengineers.in/' },
    { url: '/assets/images/machinary/hydrolic-press.png', caption: 'Hydrolic Press', href: 'https://amengineers.in/' },
    { url: '/assets/images/machinary/milling-machine.png', caption: 'Milling Machine', href: 'https://amengineers.in/' },
    { url: '/assets/images/machinary/round-blade-grinding-machine.png', caption: 'Round Blade Grinding Machine', href: 'https://amengineers.in/' },
    { url: '/assets/images/machinary/round-grinding-machine.png', caption: 'Round Grinding Machine', href: 'https://amengineers.in/' },
    { url: '/assets/images/machinary/surface-grinding-machine.png', caption: 'Surface Grinding Machine', href: 'https://amengineers.in/' }
  ];
  
  constructor(public ngxSmartModalService: NgxSmartModalService) { }

 

  ngOnInit() {
  }

  setImageURL(url){
    this.imageURL = url;
    this.ngxSmartModalService.getModal('myBootstrapModal').open();
  }

  ngAfterViewInit() {
    const obj: Object = {
      prop1: 'test',
      prop2: true,
      prop3: [{a: 'a', b: 'b'}, {c: 'c', d: 'd'}],
      prop4: 327652175423
    };

    this.ngxSmartModalService.setModalData(obj, 'myModal');
  }
}
