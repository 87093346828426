<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-menu></app-menu>
        </div>
     </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
        

          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
          </div>
      
      
      
      
      
       
          <div id="content" class="col-sm-9"><ul class="breadcrumb">
              <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
              <li><a  routerLink="/aboutUs.html">About Us</a></li>
              </ul>
              <!--<h1>About Us</h1>-->
              <div  >
                    <img src="/assets/images/banners/about-us-banner.jpg" class="img-responsive">
              </div><br/>
              <div class="panel">
                <div class="panel-body">
                    <div class="row">
                     <div class="contact_info" style="font-size: 14px;" align="justify">
                      
                            <p style="text-indent :4em;" >AM Engineers established in 2010 to match the demand of greater accuracy and total reliability at higher speed, 
                            we are a renowned manufacturer and supplier of standard industrial Gauges as well as customer specific special 
                            type of Receiver, Position gauges, Jigs & Fixtures, Soft/Hardened Jaws & Machinery Spare Parts, etc.</p>
                            <p style="text-indent :4em;" >The core of the company are the skills acquired over the years in the ﬁeld of Special Fixtures and Gauges, 
                            the knowledge of which has expanded during the years spent on development and by adapting new concept and technologies 
                            implemented through rigorous trials and research.</p>


                            <b>Industrial Background:</b><br/>
                            The Key Personal- G. Anandababu the proprietor of AM Engineers with over ten years of experience in Auto & Non
                        automotive industry in Gauge design, Calibration & Quality department.<br/><br/>


                        <b>Business:</b><br/>
                        <ul>
                            <li>Design and Manufacturing of Standard & Special Gauges.</li>
                            <li>Jigs & Fixtures.</li>
                            <li>Soft / Hardened Jaws</li>
                            <li>Machinery Spare Parts.</li>
                            <li>Straight & Taper Mandrels</li>
                        </ul>








      
                      </div>
                  </div>
                </div>
                  </div>
                </div>
    
    
    
    
    
    
    
    
    
    
    
    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  