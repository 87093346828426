import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface} from 'ngx-swiper-wrapper';
@Component({
  selector: 'app-our-testimonials',
  templateUrl: './our-testimonials.component.html',
  styleUrls: ['./our-testimonials.component.scss']
})
export class OurTestimonialsComponent implements OnInit {
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    observer: false,
    centeredSlides: false,
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: true,
    autoplay:true
  };

  public slides3 = [
    '/assets/images/testimonials/testimonial-7.png',
    '/assets/images/testimonials/testimonial-8.png',
    '/assets/images/testimonials/testimonial-9.png',
    '/assets/images/testimonials/testimonial-10.png',
    '/assets/images/testimonials/testimonial-11.png'
  ];
/*
  public slides = [
    {url:'/assets/images/testimonials/testimonial-7.png',name:'Siva Chandran',designation:'Delivery Manager',company:'Delphi Tvs Ltd',message:'I have worked with Will and the AM Engineers team for several years. Unflinchingly reliable, professional and proactive from a technology and security standpoint. Over the years, AM Engineers has streamlined our communications so that any of our website updates or other needs are handled seamlessly.'},
    {url:'/assets/images/testimonials/testimonial-8.png',name:'Ganesh Dubey',designation:'Production Manager',company:'Venu Engineering Service',message:'My words can never be enough to praise your actions because your work always meets my expectations. Thank you.'},
    {url:'/assets/images/testimonials/testimonial-9.png',name:'K. Dhinakaran',designation:'Delivery Manager',company:'Sundaram Fasteners Ltd.',message:'Thank you for being so committed. It’s only a matter of time that all your efforts will be duly remitted.'},
    {url:'/assets/images/testimonials/testimonial-10.png',name:'Sreya Rajagopalan',designation:'Production Manager',company:'Motherson Ltd.',message:'Thank you for bringing your positive attitude to work every day. Projects become easier to execute, changes become easier to implement and problems become easier to solve.'},
    {url:'/assets/images/testimonials/testimonial-female.png',name:'Bina Sukumaran',designation:'Production Manager',company:'MM Forgings Ltd..',message:'My words can never be enough to praise your actions because your work always meets my expectations. Thank you.'},
    {url:'/assets/images/testimonials/testimonial-male.png',name:'Raja Srinivasan',designation:'Delivery Manager',company:'Ford India Pvt. Ltd',message:'I have worked with Will and the AM Engineers team for several years. Unflinchingly reliable, professional and proactive from a technology and security standpoint. Over the years, AM Engineers has streamlined our communications so that any of our website updates or other needs are handled seamlessly.'}
  ];*/
  public slides = [
    {url:'/assets/images/testimonials/testimonial-male.png',name:'Client Manager',designation:'Client Manager',company:'Delphi Tvs Ltd',message:'I have worked with Will and the AM Engineers team for several years. Unflinchingly reliable, professional and proactive from a technology and security standpoint. Over the years, AM Engineers has streamlined our communications so that any of our website updates or other needs are handled seamlessly.'},
    {url:'/assets/images/testimonials/testimonial-male.png',name:'Client Manager',designation:'Client Manager',company:'Venu Engineering Service',message:'My words can never be enough to praise your actions because your work always meets my expectations. Thank you.'},
    {url:'/assets/images/testimonials/testimonial-female.png',name:'Client Manager',designation:'Client Manager',company:'Sundaram Fasteners Ltd.',message:'Thank you for being so committed. It’s only a matter of time that all your efforts will be duly remitted.'},
    {url:'/assets/images/testimonials/testimonial-male.png',name:'Client Manager',designation:'Client Manager',company:'Motherson Ltd.',message:'Thank you for bringing your positive attitude to work every day. Projects become easier to execute, changes become easier to implement and problems become easier to solve.'},
    {url:'/assets/images/testimonials/testimonial-female.png',name:'Client Manager',designation:'Client Manager',company:'MM Forgings Ltd..',message:'My words can never be enough to praise your actions because your work always meets my expectations. Thank you.'},
    {url:'/assets/images/testimonials/testimonial-male.png',name:'Client Manager',designation:'Client Manager',company:'Ford India Pvt. Ltd',message:'I have worked with Will and the AM Engineers team for several years. Unflinchingly reliable, professional and proactive from a technology and security standpoint. Over the years, AM Engineers has streamlined our communications so that any of our website updates or other needs are handled seamlessly.'}
  ];
  constructor() { }

  ngOnInit() {
  }

}
