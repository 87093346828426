import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-offerings',
  templateUrl: './our-offerings.component.html',
  styleUrls: ['./our-offerings.component.scss']
})
export class OurOfferingsComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
