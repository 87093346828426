<div class="container" style="background-color:#FFF;">
    <app-header></app-header>
    <div class="row" style="padding-top:2px;">
      <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
          <app-menu></app-menu>
      </div>
   </div>
    <div class="row" style="min-height: 800px;padding-left:10px;padding-right:10px">
          <div id="column-left" class="col-sm-3 hidden-xs">
              <app-common-left-panel></app-common-left-panel>
        </div>











        <div id="content" class="col-sm-9">
            <ul class="breadcrumb">
            <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
            <li><a  routerLink="/contactDetails.html">Contact Us</a></li>
            </ul>
            <!--<h1>Contact Details</h1>-->
            <div>
                <img src="/assets/images/banners/contact-us-banner.jpg" class="img-responsive">
            </div><br/><br/>
            
            <div class="panel panel-default"><br/>
              <h2>&nbsp;&nbsp;Our Location</h2> 
              <div class="panel-body">
                  <div class="row">
                   <div class="contact_info">
                    <div class="left" style="font-size: 14px !important;">
                      <div class="address">           
                        <strong style="font-size:20px;color:brown">AM Engineers</strong>
                        <div class="registered-address">
                            <strong>Registered Office Address:</strong>
                            <address>
                                4/76 Sakthi Nagar, Mudichur,<br/>
                                Chennai 600048,<br/>
                                Tamil Nadu, India.                       
                            </address>
                        </div>
                        <div class="registered-address">
                            <strong>Factory Address:</strong>
                            <address>
                                No.60, Womens Industrial Park,<br/>
                                Thirumudivakkam,<br/>
                                Chennai 600044,<br/>
                                Tamil Nadu, India.                       
                            </address>
                        </div>
                      </div>
                      <div class="telephone">
                        <strong>Telephone:</strong>
                        <div class="text_telephone">
                        +91 9791123622
                        </div>
                      </div>
                      <div class="email">
                        <strong>Email:</strong>
                        <div class="text_fax">
                            amengineers2010ame@gmail.com             
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <div class="map">
                          <div style="margin:0px;padding:0px;border:0px;width:650px;height:400px;display:inline-block;">
                              <agm-map [zoom]="map.zoom" class="z-depth-1-half map-container" style="height: 400px; width:470px;" [latitude]="map.lat" [longitude]="map.lng">
                                  <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
                              </agm-map>
                          </div>
                      </div>
                    </div>
                    </div>
                </div>

                <h2>Contact Form</h2>
                <form [formGroup]="enqueryForm" id="contact-form">
                    <div class="messages"></div>        
                    <div class="controls">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <label for="form_name" class="control-label">Name</label>
                                    <input id="name" type="text" name="name" class="form-control"  formControlName="name" placeholder="Please enter your name" required="required" data-error="name is required.">
                                    <div class="help-block">
                                        <small *ngIf="hasErrors('name')" class="with-errors">
                                            <span *ngIf="name.errors.maxlength">Name must be with 15 character long.</span>
                                            <span *ngIf="name.errors.minlength">Name be 2 character long.</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <label for="form_phone" class="control-label">Phone No.</label>
                                    <input id="phone" type="text" name="phone" class="form-control" formControlName="phone" placeholder="Please enter your phone no" required="required" data-error="phone is required.">
                                    <div class="help-block">
                                        <small *ngIf="hasErrors('phone')" class="with-errors">
                                            <span *ngIf="phone.errors.maxlength">Phone No. must be with 12 character long.</span>
                                            <span *ngIf="phone.errors.minlength">Phone No. be 12 character long.</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <label for="form_email" class="control-label">Email</label>
                                    <input id="form_email" type="email" name="email" class="form-control" formControlName="email" placeholder="Please enter your email" required="required" data-error="Valid email is required.">
                                    <div class="help-block">
                                            <small *ngIf="hasErrors('email')" class="with-errors">
                                                <span *ngIf="email.errors.maxlength">Email must be with 15 character long.</span>
                                                <span *ngIf="email.errors.minlength">Email must be 2 character long.</span>
                                                <span *ngIf="email.errors.pattern">Please enter correct email format.</span>
                                            </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <label for="form_need" class="control-label">Enquery Type</label>
                                    <select id="enqueryType" name="enqueryType" class="form-control" formControlName="enqueryType" style="height:30px;" required="required" data-error="Please select enquery type">
                                        <option value=""></option>
                                        <option value="Request quotation">Request quotation</option>
                                        <option value="Request order status">Request order status</option>
                                        <option value="Request copy of an invoice">Request copy of an invoice</option>
                                        <option value="Feedback">Feedback</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div class="help-block">
                                        <small *ngIf="hasErrors('enqueryType')" class="with-errors">
                                            <span *ngIf="enqueryType.errors.maxlength">Enquery Type max length is 30 character long.</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group required">
                                    <label for="form_message" class="control-label">Message</label>
                                    <textarea id="enqueryMessage" name="enqueryMessage" class="form-control" formControlName="enqueryMessage" placeholder="Type your message" rows="4" required="required" data-error="Please, leave us a message."></textarea>
                                    <div class="help-block">
                                        <small *ngIf="hasErrors('enqueryMessage')" class="with-errors">
                                            <span *ngIf="enqueryMessage.errors.maxlength">Enquery Message max length is 300 character long.</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <label for="form_captchaValue" class="control-label">Enter the code in the box below</label>
                                    <input id="form_captchaValue" type="captchaValue" name="captchaValue" class="form-control" placeholder="Please enter the code" required="required" data-error="Captcha code is required.">
                                   
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="form_need" class="control-label">&nbsp;</label><br/>
                                    <img src="/assets/images/icons/captcha.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12" style="text-align:right;">
                                <span *ngIf="submitted" class="response-status"><span *ngIf="isSuccess" class="success-message">Saved successfully.</span><span *ngIf="!isSuccess" class="errors-message">Failed to save.</span></span>
                                <input type="button" class="btn btn-default btn-send" (click)="resetForm(enqueryForm)" value="Reset" style="margin-right: 10px;">
                                <input type="button" class="btn btn-success btn-send" (click)="submitForm(enqueryForm)" value="Submit Message">
                            </div>
                        </div>
                    </div>
                
                </form>








                </div>
                </div>
                 </div>

















    </div>
    <div class="row" style="padding-top:2px;">
        <div class="col" style="padding-right: 0px !important;padding-left: 0px !important;">
            <app-footer></app-footer>
        </div>
     </div>
  </div>
  
  